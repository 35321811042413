<script setup lang="ts">
import { defineComponent, defineProps, defineEmits, ref, watch, defineSlots } from 'vue';
import vSelect from "vue-select";
// types
import { TSelectOption, ISelect } from '@/components/kit/types';
// utils
import { getUniqId } from '@/utils';


const slots = defineSlots();
const isOptionSlot = Boolean(slots.option);
const isOptionSelectedSlot = Boolean(slots.selectedOption);
const isSearchSlot = Boolean(slots.search);

// props
const props = defineProps<ISelect>();

const idi = props.id || getUniqId();
const data = ref<string | string[]>(props.modelValue || []);
const noSearch = props.noSearch || false;
const multiple = Boolean(props.multiple || false);

defineComponent({ name: "custom-select" });

// emits
const emits = defineEmits(['update:modelValue']);

watch(data, () => {
    if (multiple) {
        emits('update:modelValue', [...data.value]);
    }
    else {
        emits('update:modelValue', data.value);
    }
});

// reduce
const reduce = (option: TSelectOption | string): string => {
    if (typeof option === 'string') {
        return option
    }
    return option.value;
}

// methods
const filter = (options: TSelectOption[], search: string) => {
    if (props.customFilter) {
        return props.customFilter(options, search);
    }
    
    const regexp = new RegExp(search, 'i');

    return options.filter((item) => {
        return regexp.test(item.label) || regexp.test(item.value)
    })
}
</script>

<template>
    <div :class="[
        'formular-select',
        noSearch ? '-no-search' : '',
        multiple ? '-multiple' : ''
    ].join(' ')">
        
        <vSelect 
            :inputId="idi" 
            inputClass="formular-input" 
            :options="options" 
            :multiple="multiple" 
            :filter="filter"
            :searchable="!noSearch" 
            :disabled="Boolean(disabled)" 
            :reduce="reduce" 

            v-model="data"
        >
            <template #no-options="{}">
                Ничего не найдено
            </template>
            
            <template v-if="isOptionSlot" #option="optionItem">
              <slot name="option" :option="optionItem" />
            </template>

            <template v-if="isOptionSelectedSlot" 
              #selected-option-container="{ option, deselect, multiple, disabled }">
              <slot name="selectedOption" :option="option" :deselect="deselect" :multiple="multiple" :disabled="disabled" />
            </template>

            <template v-if="isSearchSlot" #search="{attributes, events}">
                <slot name="search" :attributes="attributes" :events="events" />
            </template>

        </vSelect>
    </div>
</template>