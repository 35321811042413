<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue';
// components
import Input from '@/components/kit/Input.vue';
// store
import { useAsyncFaqListStore } from '../store';
import { getFaqCategories } from '../bff-service';


const tableStore = useAsyncFaqListStore();
const componentDataLoaded = ref<boolean>(false);
const selectedCategoryName = ref<string>('_all');
const question_text = ref<string>('');

const categoryList = [
  {
    value: '_all',
    label: 'Все'
  }
] as {
  value: string;
  label: string;
}[];

const fetchData = async () => {
  try {
    const resCategories = await getFaqCategories();
    resCategories.list.forEach(i => categoryList.push({
      value: i.id + '',
      label: i.name,
    }));

    componentDataLoaded.value = true;
  } catch (error) {
    console.error(error);
    alert('Произошла ошибка, попробуйте перезагрузить страницу.');
  }
};

onMounted(() => {
  fetchData();
});

onUnmounted(() => {
  tableStore.$reset();
});

watch([selectedCategoryName, question_text], ({
  0: category,
  1: question
}) => {
  // фильтр по категории
  var filterByCategory = [];
  if (!category || category !== '_all') {
    filterByCategory.push(parseInt(category, 10));
  }
  tableStore.filterBy(1, filterByCategory);

  // фильтр по вопросу
  var filterByQuestion = [];
  if (question) {
    filterByQuestion.push(question);
  }
  tableStore.filterBy(2, filterByQuestion);
});



</script>

<template>
  <tbody class="cmi-deals-all-filters filter-faq">
    <tr v-if="componentDataLoaded">
      <td colspan="1" class="faq__table-tag-filter">
        Фильтры:
      </td>
      <td class="faq__table-tag-category">
        <select v-model="selectedCategoryName" class="form-control">
          <option v-for="(option, k) in categoryList" :key="k" :value="option.value">
            {{ option.label }}
          </option>
        </select>
      </td>
      <td>
        <Input v-model="question_text" type="search" />
      </td>
      <td />
    </tr>
    <tr v-else>
      <td colspan="4" class="faq__table-tag-filter">
        Загрузка фильтров...
      </td>
    </tr>
  </tbody>

  <tbody v-if="tableStore.loading" class="cmi-deals-all-filters">
    <tr>
      <td colspan="8">
        Загрузка:
      </td>
    </tr>
  </tbody>
</template>

<style lang="scss">
.faq__table-tag-filter {
  width: 100px;
}

.faq__table-tag-category {
  width: 300px;

  .vs__selected {
    max-width: 100px;
    font-size: 14px;
    line-height: 1.05em;
  }

  .vs__dropdown-toggle {
    max-width: 160px;
  }

  .formular-select {
    max-width: 170px;
  }

  input[type="search"] {
    display: none !important;
  }
}

.filter-faq li {
  word-break: break-word;
  color: #505050;
  white-space: break-spaces;
}

.filter-faq .formular-select {
  max-width: 450px;
}

.cmi-deals-all-filters {
  td {
    color: gray;

    input.form-control {
      padding: 5px;
      min-width: 100px;
    }
  }
}
</style>
