import {
	generateAsyncDataTableStore,
	definePromiseQueue,
	mapTableFiltersToSearchParams,
} from '@/components/Table';
import { getChannelsList, TChannelsSearchParams } from '../bff-service';  // Импорт функции для получения мок-данных
import { TChannelsGetListResDto,  } from '../types';

export type TChannelData = {
	id: number;
	channel_id: string;
	name: string;
}

const allListPromiseQueue = definePromiseQueue<TChannelsGetListResDto>();

export const useAsyncChannelAllListStore = generateAsyncDataTableStore<TChannelData>({
	initialState: {
			loading: true,
	},

	dataGetter: async (useStore) => {
		const store = useStore();

		await allListPromiseQueue.wait(allListGetter, useStore)
			.then(response=>{
				store.data = response.list.map( deal => {
					return {
						id: deal.id,
						channel_id: deal.channel_id,
						name: deal.name, 
					};
				});
				store.paginateTotal = response.paginate.filtered;
				store.paginateFiltered = response.paginate.filtered;
			})
			.catch(e=>{
				store.data = [];
				store.paginateTotal = 0;
			})
			.finally(()=>{
				store.loading = false;
			})
	}
});


// получение списка заявок
const allListGetter = (): Promise<TChannelsGetListResDto> => {
	const store = useAsyncChannelAllListStore();

	// приводим данные фильтров стора таблиц 
	// к виду данных для поиска
	const search = mapTableFiltersToSearchParams<typeof store, TChannelsSearchParams>(
		store, // указываем нужный стор
		// указываем данные поиска по дефолту
		{
			channel_id: '',
			name: '',
		},
		// указываем соответствие ключей строра таблиц к ключам данных для поиска
		{
			channel_id: 'channel_id',
			name: 'name',
		}
	);

	return getChannelsList(store.paginatePage, store.paginatePerPage, search);
}