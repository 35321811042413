<script setup lang="ts">

</script>

<template>
    <tr class="black-list-table-row">
      <slot />
    </tr>
</template>

<style lang="scss">
.black-list-table-row {
    cursor: pointer;
}
</style>