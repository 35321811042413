import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { style: {"color":"#000"} }

import { onMounted, onUnmounted, ref } from 'vue';
import { isUserCanEditDictionary, isUserCanViewDictionary } from '@/store';
import PageHeader from '@/components/kit/PageHeader.vue';
import Card from '@/components/kit/Card.vue';
import CardTitle from '@/components/kit/CardTitle.vue';
import { useRoute } from 'vue-router';
import { TJsonUseStore } from 'litsystems-vue-json-editor-dev/src';
import JsonViewer from 'litsystems-vue-json-editor-dev/src/JsonViewer.vue';
import { useDictionaryEditItemStore } from './store/dictionaryItemStore';
import InputTagDictionaryRegulars from './components/InputTagDictionaryRegulars.vue';
import InputTagDictionaryBanWord from './components/InputTagDictionaryBanWord.vue';

// store

export default /*@__PURE__*/_defineComponent({
  __name: 'DictionaryEdit',
  setup(__props) {

const store = useDictionaryEditItemStore();
const dealId = ref<number>(NaN);

var useDictionaryStore: TJsonUseStore['useStore'];

const getUseStoreCb = (useStore: TJsonUseStore['useStore']) => {
  useDictionaryStore = useStore;

    const jsonStore = useStore();

    // устанавливаем расширения
    jsonStore.addExtensionComponent('dictionary-ban-word', InputTagDictionaryBanWord);
    jsonStore.addExtensionComponent('dictionary-input', InputTagDictionaryRegulars);

    jsonStore.updateExtensions({
      key: {
        'words': ['dictionary-ban-word'],
        'regulars': ['dictionary-input'],
      },
    });

    // устанавливаем классы
    jsonStore.updateClassNames({
      viewer: {
        key: {
          'words': "--hidden-row",
          'regulars': "--hidden-row",
        }
      }
    });

    jsonStore.updateHiddens({
      parentKey: [''],

      keyException: [
        'words',
        'regulars',
      ]
    });

    // изначальная установка даннных
    jsonStore.setReference(store.dictionary);
}


onMounted(() => {
    const route = useRoute();
    const deal = parseInt((route?.params?.id ?? '') + '', 10);

    // получаем данные по id страницы
    if (Number.isInteger(deal)) {
        dealId.value = deal;
        store.getDataById(deal); // Дождитесь получения данных
    }
});

onUnmounted(() => {
    store.$reset();
    useDictionaryStore?.().$reset();
});

const saveDictionaryHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();


    try {
        const dictionaryStore = useDictionaryStore();
        const referenceData = JSON.stringify(dictionaryStore.reference, null, 2);
        store.updateData(JSON.parse(referenceData));

    } catch (error) {
        console.error('Ошибка при сохранении изменений:', error);
    }
};


return (_ctx: any,_cache: any) => {
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageHeader, null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("Редактирование словаря с запрещенными словами")
      ])),
      _: 1
    }),
    (!_unref(isUserCanViewDictionary)())
      ? (_openBlock(), _createBlock(Card, {
          key: 0,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            _createVNode(CardTitle, null, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Нет прав на просмотр данных")
              ])),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(isUserCanEditDictionary)())
      ? (_openBlock(), _createBlock(Card, {
          key: 1,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            (_unref(store).$loading)
              ? (_openBlock(), _createBlock(_component_Loader, { key: 0 }))
              : (_unref(store).$error)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _cache[5] || (_cache[5] = _createElementVNode("h3", null, "Произошла ошибка получения данных.", -1)),
                    _cache[6] || (_cache[6] = _createElementVNode("p", null, [
                      _createElementVNode("strong", null, "Попробуйте перезагрузить страницу"),
                      _createTextVNode(", если это не поможет "),
                      _createElementVNode("strong", null, "обратитесь в поддержку"),
                      _createTextVNode(". ")
                    ], -1)),
                    _cache[7] || (_cache[7] = _createElementVNode("p", null, [
                      _createElementVNode("strong", null, "Данные об ошибке.")
                    ], -1)),
                    _createElementVNode("p", null, [
                      _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Обозначение ошибки: ", -1)),
                      _createTextVNode(_toDisplayString(_unref(store).$error.error), 1)
                    ]),
                    _createElementVNode("p", null, [
                      _cache[3] || (_cache[3] = _createElementVNode("strong", null, "ID запроса: ", -1)),
                      _createTextVNode(_toDisplayString(_unref(store).$error.requestId), 1)
                    ]),
                    _createElementVNode("p", null, [
                      _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Код ответа: ", -1)),
                      _createTextVNode(_toDisplayString(_unref(store).$error.code ?? ''), 1)
                    ]),
                    _cache[8] || (_cache[8] = _createElementVNode("p", null, " ", -1))
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createVNode(CardTitle, null, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode("Данные словаря")
                      ])),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_1, [
                      _cache[10] || (_cache[10] = _createElementVNode("p", null, "Название словаря:", -1)),
                      _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(store).dictionary_name), 1)
                    ]),
                    _createVNode(CardTitle, null, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode("Редактирование словаря")
                      ])),
                      _: 1
                    }),
                    _createVNode(JsonViewer, { getUseStoreCb: getUseStoreCb }),
                    (_unref(store).isUpdated)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "btn btn-inverse-primary btn-fw",
                          onClick: saveDictionaryHandler
                        }, "Сохранить изменения"))
                      : _createCommentVNode("", true)
                  ], 64))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})