<script setup lang="ts">

</script>

<template>
    <tr class="moderator-table-row">
      <slot />
    </tr>
</template>

<style lang="scss">
.moderator-table-row {
    cursor: pointer;
}
</style>