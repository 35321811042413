import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "d-flex gap-2 align-items-center"
}

import { ref } from 'vue';
import { deleteModerator, updateModerator } from '../bff-service';
import { useAsyncModeratorAllListStore } from '../store';
import { ITableCellProps } from '@/components/Table/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModeratorEditTable',
  props: {
    dataKey: {},
    rowData: {},
    rowIndex: {},
    value: {},
    class: {}
  },
  setup(__props: any) {

const props = __props;

const tableStore = useAsyncModeratorAllListStore();
const isEditing = ref(false);
const isSuperModerator = ref(props.rowData.is_super_moderator);

const toggleEditing = () => {
  isEditing.value = !isEditing.value;
};

const applyEditing = async () => {
  try {
    await updateModerator(props.rowData.id, { is_super_moderator: isSuperModerator.value });
    await tableStore.getData(); 
    isEditing.value = false; 
  } catch (error) {
    console.error('Ошибка при обновлении модератора', error);
  }
};

const cancelEditing = () => {
  isEditing.value = false;
  isSuperModerator.value = props.rowData.is_super_moderator; 
};


const deleteModeratorById = () => {

var isApproved = window.confirm(`Удалить модератора ${props.rowData.description} (${props.rowData.telegram_user_id}) ?`);
if (!isApproved) { return }

deleteModerator(props.rowData.id)
  .then(()=>{
    tableStore.getData()
  })
  .catch((e)=>{
    alert(`Ошибка удаления модератора.\n${e.error}\nRequest ID: ${e.requestId}`)
  })
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("td", null, [
    (!isEditing.value)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "btn btn-outline-secondary btn-icon",
          title: "Редактировать",
          onClick: toggleEditing
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("i", { class: "mdi mdi-pencil" }, null, -1)
        ])))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[5] || (_cache[5] = _createElementVNode("label", {
            class: "form-check-label me-2",
            for: "superCheckbox"
          }, "Супер", -1)),
          _withDirectives(_createElementVNode("input", {
            id: "superCheckbox",
            class: "form-check-input",
            type: "checkbox",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isSuperModerator).value = $event))
          }, null, 512), [
            [_vModelCheckbox, isSuperModerator.value]
          ]),
          _createElementVNode("button", {
            class: "btn btn-outline-success btn-icon",
            title: "Применить редактирование",
            onClick: applyEditing
          }, _cache[2] || (_cache[2] = [
            _createElementVNode("i", { class: "mdi mdi-check" }, null, -1)
          ])),
          _createElementVNode("button", {
            class: "btn btn-outline-primary btn-icon",
            title: "Отменить редактирование",
            onClick: cancelEditing
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("i", { class: "mdi mdi-close" }, null, -1)
          ])),
          _createElementVNode("button", {
            class: "btn btn-outline-danger btn-icon",
            title: "Удалить",
            onClick: deleteModeratorById
          }, _cache[4] || (_cache[4] = [
            _createElementVNode("i", { class: "fa fa-trash-o" }, null, -1)
          ]))
        ]))
  ]))
}
}

})