<script setup lang="ts"></script>

<template>
        <div class="new-year">
            <div class="new-year-box">

                <svg class="new-year-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297 297">
                    <g>
                        <path class="new-year-icon-mid-back" d="M215.825,161.602l-15.787-37.382c-15.249,16.204-50.068,40.992-120.401,41.024l-15.42,36.512
		c66.481-1.602,105.158-13.407,126.245-23.429C203.44,172.161,211.312,165.994,215.825,161.602z"></path>
                        <path class="new-year-icon-mid" d="M215.825,161.602l-15.787-37.382c-15.249,16.204-50.068,40.992-120.401,41.024l-15.42,36.512
		c66.481-1.602,105.158-13.407,126.245-23.429C203.44,172.161,211.312,165.994,215.825,161.602z"></path>
                        <path class="new-year-icon-bottom-back" d="M247.744,237.182l-23.417-55.446c-5.379,4.335-12.48,9.092-21.797,13.775c-34.665,17.425-84.146,26.547-147.137,27.141
		l-6.138,14.53c-1.353,3.202-1.011,6.868,0.91,9.766c1.921,2.896,5.165,4.638,8.642,4.638h61.384v35.047
		c0,5.726,4.643,10.368,10.369,10.368h35.879c5.727,0,10.369-4.643,10.369-10.368v-35.047h61.384c3.477,0,6.721-1.742,8.642-4.638
		C248.755,244.05,249.097,240.384,247.744,237.182z"></path>
                        <path class="new-year-icon-bottom" d="M247.744,237.182l-23.417-55.446c-5.379,4.335-12.48,9.092-21.797,13.775c-34.665,17.425-84.146,26.547-147.137,27.141
		l-6.138,14.53c-1.353,3.202-1.011,6.868,0.91,9.766c1.921,2.896,5.165,4.638,8.642,4.638h61.384v35.047
		c0,5.726,4.643,10.368,10.369,10.368h35.879c5.727,0,10.369-4.643,10.369-10.368v-35.047h61.384c3.477,0,6.721-1.742,8.642-4.638
		C248.755,244.05,249.097,240.384,247.744,237.182z"></path>
                        <path class="new-year-icon-star-back" d="M122.447,45.42l-0.521,15.828c-0.082,2.537,1.093,4.955,3.144,6.447c1.319,0.964,2.89,1.462,4.479,1.462
		c0.874,0,1.753-0.151,2.598-0.458l14.798-5.383l14.798,5.383c0.844,0.309,1.725,0.462,2.599,0.462c1.588,0,3.161-0.5,4.479-1.466
		c2.05-1.492,3.225-3.91,3.143-6.447l-0.521-15.828l9.67-12.492c1.553-2.006,2.016-4.651,1.232-7.067
		c-0.781-2.417-2.704-4.285-5.134-4.991l-15.123-4.392L153.258,3.37C151.844,1.266,149.474,0,146.943,0
		c-2.53,0-4.9,1.266-6.314,3.37l-8.826,13.107l-15.123,4.392c-2.429,0.706-4.353,2.574-5.135,4.991
		c-0.782,2.416-0.318,5.062,1.232,7.067L122.447,45.42z"></path>
                        <path class="new-year-icon-star" d="M122.447,45.42l-0.521,15.828c-0.082,2.537,1.093,4.955,3.144,6.447c1.319,0.964,2.89,1.462,4.479,1.462
		c0.874,0,1.753-0.151,2.598-0.458l14.798-5.383l14.798,5.383c0.844,0.309,1.725,0.462,2.599,0.462c1.588,0,3.161-0.5,4.479-1.466
		c2.05-1.492,3.225-3.91,3.143-6.447l-0.521-15.828l9.67-12.492c1.553-2.006,2.016-4.651,1.232-7.067
		c-0.781-2.417-2.704-4.285-5.134-4.991l-15.123-4.392L153.258,3.37C151.844,1.266,149.474,0,146.943,0
		c-2.53,0-4.9,1.266-6.314,3.37l-8.826,13.107l-15.123,4.392c-2.429,0.706-4.353,2.574-5.135,4.991
		c-0.782,2.416-0.318,5.062,1.232,7.067L122.447,45.42z"></path>
                        <path class="new-year-icon-top-back" d="M190.887,102.555l-9.902-23.448c-0.746,0.707-1.54,1.372-2.383,1.986c-4.168,3.047-9.099,4.658-14.262,4.658
		c-2.84,0-5.633-0.496-8.27-1.461l-9.127-3.32l-9.137,3.324c-2.656,0.964-5.434,1.453-8.259,1.453
		c-5.163,0-10.096-1.61-14.238-4.637c-0.036-0.026-0.069-0.056-0.105-0.082L88.46,144.355c41.251-1.403,66.709-12.365,81.058-21.664
		C182.557,114.243,188.843,105.721,190.887,102.555z"></path>
                        <path class="new-year-icon-top" d="M190.887,102.555l-9.902-23.448c-0.746,0.707-1.54,1.372-2.383,1.986c-4.168,3.047-9.099,4.658-14.262,4.658
		c-2.84,0-5.633-0.496-8.27-1.461l-9.127-3.32l-9.137,3.324c-2.656,0.964-5.434,1.453-8.259,1.453
		c-5.163,0-10.096-1.61-14.238-4.637c-0.036-0.026-0.069-0.056-0.105-0.082L88.46,144.355c41.251-1.403,66.709-12.365,81.058-21.664
		C182.557,114.243,188.843,105.721,190.887,102.555z"></path>
                    </g>
                </svg>
            </div>
        </div>
</template>

<style>
.navbar-brand-wrapper{
    position: relative;
}

.brand-logo-mini {
    position: relative;
    z-index: 2;
}

@media (max-width: 991px) {
    .new-year {
        display: none;
    }
}

.new-year {
    position: absolute;
    width: 46px;
    height: 45px;
    right: 40px;
    bottom: 9px;
    z-index: 1;
    opacity: .75;
}

.new-year-box {
    width: 100%;
    height: 47px;
    overflow: hidden;
}

.new-year-icon {
    display: block;
    height: 47px;
}

.new-year-icon-star-back {
    fill: #d8d8d8;
}

.new-year-icon-star {
    fill: #ef0404;
    opacity: 0;
    animation-delay: 3s;
    animation-duration: 2.5s;
    animation-name: new-year-icon-star-animation;
    animation-fill-mode: forwards;
}

@keyframes new-year-icon-star-animation {
    0% {
        opacity: 0;
    }

    70% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.new-year-icon-top-back {
    fill: #d8d8d8;
}

.new-year-icon-top {
    fill: #008a00;
    opacity: 0;
    animation-duration: 2.5s;
    animation-name: new-year-icon-top-animation;
    animation-fill-mode: forwards;
}

@keyframes new-year-icon-top-animation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: .6;
    }
}

.new-year-icon-mid-back {
    fill: #d8d8d8;
}

.new-year-icon-mid {
    fill: #008a00;
    opacity: 0;
    animation-delay: .5s;
    animation-duration: 2.5s;
    animation-name: new-year-icon-mid-animation;
    animation-fill-mode: forwards;
}

@keyframes new-year-icon-mid-animation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: .8;
    }
}

.new-year-icon-bottom-back {
    fill: #d8d8d8;
}

.new-year-icon-bottom {
    fill: #008a00;
    opacity: 0;
    animation-delay: 1s;
    animation-duration: 2.5s;
    animation-name: new-year-icon-bottom-animation;
    animation-fill-mode: forwards;
}

@keyframes new-year-icon-bottom-animation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
</style>