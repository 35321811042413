<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { defineComponent } from 'vue';
import { createModerator, getList } from './bff-service'; // Импортируем функцию из BFF-сервиса.
// components
import {
    Card,
    CardTitle,
    MinWidthAndScroll,
    Table,
    TablePagination,
} from '@/components/kit';
import { EmptyTr, RowModerators as TableRow } from './components';
import AllModeratorsFilters from './components/AllModeratorsFilters.vue';
// store
import { useAsyncModeratorAllListStore } from './store';
import { isUserCanEditModerators } from '@/store';
import ModeratorEditTable from './components/ModeratorEditTable.vue';
import ModeratorCell from './components/ModeratorCell.vue';

const tableStore = useAsyncModeratorAllListStore();

tableStore.columns = [
  { key: 'id', title: '#' },
  { key: 'telegram_user_id', title: 'ID модератора', searchable: true, filterable: true },
  { key: 'description', title: 'Никнейм', searchable: true, filterable: true },
  { key: 'is_super_moderator', title: 'Супер', component: ModeratorCell },
  { key: 'edit', title: 'Редактирование', component: ModeratorEditTable },
];

tableStore.paginateInfoTpl = '_DATA_ из _TOTAL_';
tableStore.body = { emptyComponent: EmptyTr };
tableStore.head = { class: 'head-no-select' };
if (!tableStore.row) {
  tableStore.row = {};
}
tableStore.row.component = TableRow;

if (isUserCanEditModerators()) {
    tableStore.getData();
}

const isCreateModeratorVisible = ref(false);

// данные в инпутах при добавление модера
const newModerator = ref({
    channel_id: 'all',
    is_super_moderator: false,
    telegram_user_id: '', 
    description: '',
});

const errorMessages = ref({
    telegram_user_id: '',
    description: '',
});

const validateTelegramId = (event: Event) => {
  const input = event.target as HTMLInputElement;
  input.value = input.value.replace(/[^0-9]/g, ''); 
  newModerator.value.telegram_user_id = input.value;
};

const validateDescription = (event: Event) => {
  const input = event.target as HTMLInputElement;
  input.value = input.value.replace(/[^a-zA-Z0-9_@]/g, ''); 
  newModerator.value.description = input.value;
};

const moderatorsIds = ref<string[]>([]);

watch(
  () => tableStore.data, // При загрузке data не успевает подгружаться, следим за обновлением
  (newData) => {
    moderatorsIds.value = newData.map((moderator) => moderator.telegram_user_id);
  },
  { immediate: true } 
);

const validateFields = async () => {
  const errors = {
    telegram_user_id: '',
    description: '',
  };

  const responseById = await getList(1, 10, { telegram_user_id: newModerator.value.telegram_user_id });
  if (responseById && responseById.list.some(item => item.telegram_user_id === newModerator.value.telegram_user_id)){
    errors.telegram_user_id = 'Модератор с таким ID уже существует';
  }

  const responseByName = await getList(1, 10, { description: newModerator.value.description });
  if (responseByName && responseByName.list.some(item => item.description === newModerator.value.description)) {
    errors.description = 'Модератор с таким Именем уже существует';
  }

  errorMessages.value = errors;

  return !errors.telegram_user_id && !errors.description;
};

const createNewModerator = async () => {
    
    const isValid = await validateFields();
    if(!isValid) return;

    const response = await createModerator(newModerator.value);
    if(response){
        newModerator.value = {
            channel_id: 'all',
            is_super_moderator: false,
            telegram_user_id: '', 
            description: '',
        };
        isCreateModeratorVisible.value = false;
        tableStore.getData(); 
    } 
};

defineComponent({ name: 'AllModerationTable' });
</script>

<template>
  <Card v-if="!isUserCanEditModerators()" style="position:relative">
    <CardTitle>Нет прав на просмотр данных</CardTitle>
  </Card>

  <Card v-if="isUserCanEditModerators()" style="position:relative">
    <CardTitle>Модераторы в канале</CardTitle>
    <div class="col-lg-6">
      <p>
        <button
          class="btn btn-inverse-primary btn-fw"
          @click="isCreateModeratorVisible = !isCreateModeratorVisible"
        >
          Добавить модератора
        </button>
      </p>

      <div v-if="isCreateModeratorVisible" class="mb-4">
        <div class="form-group">
          <label for="telegram_user_id">ID Модератора</label>
          <input
            type="text"
            id="telegram_user_id"
            class="form-control"
            v-model="newModerator.telegram_user_id"
            @input=validateTelegramId
            placeholder="Для ввода доступны только цифры"
          />
          <span v-if="errorMessages.telegram_user_id" class="text-danger">
            {{ errorMessages.telegram_user_id }}
          </span>
        </div>
        <div class="form-group">
          <label for="description">Никнейм</label>
          <input
            type="text"
            id="description"
            class="form-control"
            v-model="newModerator.description"
            @input=validateDescription
            placeholder="Символы a-z, 0-9 и подчеркивания. Минимальная длина - 5 символов"
          />
          <span v-if="errorMessages.description" class="text-danger">
            {{ errorMessages.description }}
          </span>
        </div>
        <div class="form-group form-check-no-margin">
          <input
            type="checkbox"
            id="is_super_moderator"
            class="form-check-input"
            v-model="newModerator.is_super_moderator"
          />
          <label for="is_super_moderator" class="form-check-label">
            Супермодератор
          </label>
        </div>
        <button class="btn btn-success" @click="createNewModerator" :disabled="!newModerator.telegram_user_id.trim() || !newModerator.description.trim()">
          Создать
        </button>
      </div>
    </div>

    <Row>
      <MinWidthAndScroll>
        <Table :use-store="useAsyncModeratorAllListStore">
          <AllModeratorsFilters />
        </Table>
      </MinWidthAndScroll>
    </Row>

    <TablePagination
      class="tab-content tab-transparent-content"
      :use-store="useAsyncModeratorAllListStore"
    />
  </Card>
</template>

<style>
.text-danger {
  color: red;
  font-size: 0.875rem;
}

.form-group input::placeholder {
  opacity: 0.6;
}

.form-check-no-margin{
  position: relative;
  margin-top: 15px;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
}

.form-check-no-margin .form-check-input{
  float: left;
  margin-left: -1.5em;
}

.form-check-no-margin .form-check-label{
  display: block;
  margin-left: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
</style>