<script setup lang="ts">
import { ref } from 'vue';
import { deleteModerator, updateModerator } from '../bff-service';
import { useAsyncModeratorAllListStore } from '../store';
import { ITableCellProps } from '@/components/Table/types';

const props = defineProps<ITableCellProps>();

const tableStore = useAsyncModeratorAllListStore();
const isEditing = ref(false);
const isSuperModerator = ref(props.rowData.is_super_moderator);

const toggleEditing = () => {
  isEditing.value = !isEditing.value;
};

const applyEditing = async () => {
  try {
    await updateModerator(props.rowData.id, { is_super_moderator: isSuperModerator.value });
    await tableStore.getData(); 
    isEditing.value = false; 
  } catch (error) {
    console.error('Ошибка при обновлении модератора', error);
  }
};

const cancelEditing = () => {
  isEditing.value = false;
  isSuperModerator.value = props.rowData.is_super_moderator; 
};


const deleteModeratorById = () => {

var isApproved = window.confirm(`Удалить модератора ${props.rowData.description} (${props.rowData.telegram_user_id}) ?`);
if (!isApproved) { return }

deleteModerator(props.rowData.id)
  .then(()=>{
    tableStore.getData()
  })
  .catch((e)=>{
    alert(`Ошибка удаления модератора.\n${e.error}\nRequest ID: ${e.requestId}`)
  })
}

</script>

<template>
  <td>
    <button 
      v-if="!isEditing" 
      class="btn btn-outline-secondary btn-icon" 
      title="Редактировать" 
      @click="toggleEditing"
    >
      <i class="mdi mdi-pencil"></i>
    </button>

    <div v-else class="d-flex gap-2 align-items-center">
      <label class="form-check-label me-2" for="superCheckbox">Супер</label>
      <input 
        id="superCheckbox" 
        class="form-check-input" 
        type="checkbox" 
        v-model="isSuperModerator"
      />

      <button 
        class="btn btn-outline-success btn-icon" 
        title="Применить редактирование" 
        @click="applyEditing"
      >
        <i class="mdi mdi-check"></i>
      </button>
      <button 
        class="btn btn-outline-primary btn-icon" 
        title="Отменить редактирование" 
        @click="cancelEditing"
      >
        <i class="mdi mdi-close"></i>
      </button>
      <button 
        class="btn btn-outline-danger btn-icon" 
        title="Удалить" 
        @click="deleteModeratorById"
      >
        <i class="fa fa-trash-o"></i>
      </button>
    </div>
  </td>
</template>
