export type NameKeys = {
  [key: string]: string;
};

export const nameKeysModerator: NameKeys = {
  channel: 'Наименование канала',
  telegram_user_id: 'ID модератора',
  is_super_moderator: 'Является супермодератором?',
  description: 'Никнейм модератора',
};

export const nameKeysDictionary: NameKeys = {
  words: 'Все запрещенные слова',
  regulars: 'Регулярные выражения',
};
