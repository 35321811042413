import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex gap-2 align-items-center"
}
const _hoisted_2 = ["textContent"]
const _hoisted_3 = {
  key: 1,
  class: "d-flex flex-column gap-2 align-items-start"
}
const _hoisted_4 = {
  key: 0,
  class: "text-danger"
}

import { TCategoryData } from '../store';

import { ref } from 'vue';
import { getFaqCategory } from '../bff-service';


export default /*@__PURE__*/_defineComponent({
  __name: 'InputCategoryName',
  props: {
    rowData: {}
  },
  setup(__props: any) {

const props = __props;
const { rowData } = props;

const errorMessage = ref<string>(''); 

const validateName = async (name: string, currentId: number) => {
  const responseByName = await getFaqCategory(1, 10, { name, description: '' });
  // Проверяем, есть ли совпадение по имени, игнорируя текущую категорию
  if (
    responseByName &&
    responseByName.list.some(
      (item) => item.name.toLowerCase() === name.toLowerCase() && item.id !== currentId
    )
  ) {
    errorMessage.value = 'Такая категория уже есть.';
    return false;
  }
  errorMessage.value = ''; // Сбрасываем ошибку, если всё ок
  return true;
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("td", null, [
    (!_unref(rowData).isEditing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("span", {
            textContent: _toDisplayString(_unref(rowData).name)
          }, null, 8, _hoisted_2)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass(["category-input-table", { 'is-invalid': errorMessage.value }]),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(rowData).name) = $event)),
            onBlur: _cache[1] || (_cache[1] = ($event: any) => (validateName(_unref(rowData).name, _unref(rowData).id)))
          }, null, 34), [
            [
              _vModelText,
              _unref(rowData).name,
              void 0,
              { trim: true }
            ]
          ]),
          (errorMessage.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(errorMessage.value), 1))
            : _createCommentVNode("", true)
        ]))
  ]))
}
}

})