<script setup lang="ts">

import { defineComponent, ref } from 'vue';
// store
import {
    useUserStore,
    useNavyStore
} from '../store';
import defaultUserImage from '@/assets/img/user.svg';
import Informer from './Informer.vue';


// define component
defineComponent({ name: 'AppHeader' });
const userNavOpen = ref<boolean>(false);


// store
const user = useUserStore();
const navy = useNavyStore();

// methods
const toggleUserNav = () => {
    userNavOpen.value = !userNavOpen.value;
};

const toggleLeftNavy = () => {
    navy.toggleLeftNavy();
};

const toggleMobileNavy = () => {
    navy.toggleMobileNavy();
};

// window.addEventListener('click', (e: Event) => {
//   console.log(userNavOpen.value)
//   // console.log(document.querySelector('.dropdown-menu.navbar-dropdown.dropdown-menu-end.p-0.border-0.font-size-sm'))
//   // console.log((e.target as any)?.closest('.dropdown-menu.navbar-dropdown.dropdown-menu-end.p-0.border-0.font-size-sm'))
//   if (userNavOpen.value) {
//     if ((e.target as any)?.closest('.dropdown-menu.navbar-dropdown.dropdown-menu-end.p-0.border-0.font-size-sm') === null) {
//       userNavOpen.value = false
//     }
//   }
// }) // @dev:
</script>

<template>
  <nav class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
      <Informer />
      <a
        class="navbar-brand brand-logo"
        href="/"
      >
        <img
          alt="logo"
          src="https://tupa24.de/media/2022/12/logo.png"
        >
      </a>
      <a
        class="navbar-brand brand-logo-mini"
        href="/"
      >
        <img
          alt="logo"
          src="https://tupa24.de/media/2022/12/logo.png"
        >
      </a>
    </div>
    <div class="navbar-menu-wrapper d-flex align-items-stretch">
      <button
        class="navbar-toggler navbar-toggler align-self-center"
        type="button"
        data-toggle="minimize"
        @click="toggleLeftNavy"
      >
        <span class="mdi mdi-menu" />
      </button>

      <ul class="navbar-nav navbar-nav-right">
        <li class="nav-item nav-profile dropdown">
          <a
            data-bs-toggle="dropdown"
            aria-expanded="false"
            :class="['nav-link dropdown-toggle', userNavOpen ? 'show' : ''].join(' ')"
            @click="toggleUserNav"
          >
            <div class="nav-profile-img">
              <img
                :alt="user.name"
                :src="user.avatar || defaultUserImage"
              >
            </div>
            <div class="nav-profile-text">
              <p class="mb-1 text-black">{{ user.name }}</p>
            </div>
          </a>
          <div
            class="dropdown-menu navbar-dropdown dropdown-menu-end p-0 border-0 font-size-sm"
            :class="userNavOpen && 'show'" 
            aria-labelledby="profileDropdown"
            data-x-placement="bottom-end"
          >
            <div class="p-3 text-center bg-primary">
              <img
                class="img-avatar img-avatar48 img-avatar-thumb"
                :alt="user.name"
                :src="user.avatar || defaultUserImage"
              >
            </div>
            <div class="p-2">
              <h5 class="dropdown-header text-uppercase ps-2 text-dark">
                User Options
              </h5>
              <a class="dropdown-item py-1 d-flex align-items-center justify-content-between">
                <span>{{ user.name }}</span>
              </a>
              <a class="dropdown-item py-1 d-flex align-items-center justify-content-between">
                <span>{{ user.email }}</span>
              </a>
              <div
                role="separator"
                class="dropdown-divider"
              />
              <h5 class="dropdown-header text-uppercase  ps-2 text-dark mt-2">
                Actions
              </h5>
              <a @click="()=>user.logout()" class="dropdown-item py-1 d-flex align-items-center justify-content-between">
                <span>Log Out</span>
                <i class="mdi mdi-logout ms-1" />
              </a>
            </div>
          </div>
        </li>
      </ul>
      <button 
        class="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
        type="button"
        data-toggle="offcanvas"
        @click="toggleMobileNavy"
      >
        <span class="mdi mdi-menu" />
      </button>
    </div>
  </nav>
</template>

<style scoped>
.navbar {
  z-index: 1000000;
}
.navbar .navbar-brand-wrapper .navbar-brand img {
    height: auto;
    max-height: 45px;
    width: auto;
}

.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
    height: auto;
    width: calc(100% - 20px);
}

@media (max-width: 991px) {
  .navbar {
    box-shadow: 0px 1px 3px #f0f1f7;
  }
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-img img {
  box-shadow: 0 0 5px 0px #adadad;
}
</style>