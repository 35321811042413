<script setup lang="ts">
import { ENavy, router } from '@/router';
import { ITableCellProps } from '@/components/Table/types';

const props = defineProps<ITableCellProps>();

const clickHandler = (e: Event)=>{
    e.preventDefault();
    e.stopPropagation();
    
    if (props.rowData.id) {
        router.push(ENavy.faqQuestionEdit.replace(':id', props.rowData.id.toString()));
    }
}

</script>

<template>
  <td>
    <button @click="clickHandler"
      class="btn btn-outline-secondary btn-icon" 
      title="Редактировать" 
    >
      <i class="mdi mdi-pencil"></i>
    </button>
  </td>
</template>
