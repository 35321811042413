import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "key-label margin-top" }
const _hoisted_2 = { class: "tagsinput" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["onClick"]

import { ref, computed } from 'vue';
import { TJsonExtensionProps } from 'litsystems-vue-json-editor-dev/src';
import { useDictionaryEditItemStore } from '../store';
import { NameKeys, nameKeysDictionary } from '../types';


export default /*@__PURE__*/_defineComponent({
  __name: 'InputTagDictionaryRegulars',
  props: {
    type: {},
    propKey: {},
    keyForVue: {},
    parentKeys: {},
    humanKey: {},
    childs: {},
    value: { type: [String, Number, Boolean, null] },
    className: {},
    extension: {},
    hidden: { type: Boolean },
    useStore: {}
  },
  setup(__props: any) {

const props = __props;

const editStore = useDictionaryEditItemStore();

const labelName = computed(() => {
  return nameKeysDictionary[props.propKey as keyof NameKeys] || props.propKey;
});

const inputValue = ref('');

const updateStore = () => {
  const jsonStore = props.useStore();
  jsonStore.setValueByKeys(
    [...props.parentKeys, props.propKey].slice(1),
    [...editStore.dictionary.regulars]
  );
};

const addTag = (tag: string) => {
  const newTag = tag.trim();
  if (newTag && !editStore.dictionary.regulars.includes(newTag)) {
    editStore.isUpdated = true;
    editStore.dictionary.regulars.push(newTag);
    updateStore();
  }
};

const handleInput = (e: KeyboardEvent) => {
  const input = e.target as HTMLTextAreaElement;

  if (e.key === 'Backspace' && !input.value.trim() && editStore.dictionary.regulars.length) {
    editStore.isUpdated = true;
    editStore.dictionary.regulars.pop();
    updateStore();
    return;
  }

  if (e.key === 'Enter') {
    e.preventDefault();
    e.stopPropagation();

    if (props.propKey === 'regulars') {
      addTag(input.value);
    } else {
      const newTags = input.value
        .split(',')
        .map((tag) => tag.trim())
        .filter((tag, index, self) =>
          tag && !editStore.dictionary.regulars.includes(tag) && self.indexOf(tag) === index
        );

      if (newTags.length) {
        editStore.isUpdated = true;
        editStore.dictionary.regulars.push(...newTags);
        updateStore();
      }
    }

    input.value = '';
    inputValue.value = '';
  }
};

const handleButtonClick = () => {
  if (inputValue.value.trim()) {
    addTag(inputValue.value);
    inputValue.value = '';
  }
};

const removeTag = (index: number) => {
  editStore.isUpdated = true;
  editStore.dictionary.regulars.splice(index, 1);
  updateStore();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(labelName.value), 1),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputValue).value = $event)),
        class: "form-control",
        placeholder: "Добавьте регулярное выражение (через запятую, нажмите Enter для добавления, либо по кнопке ниже)",
        onKeydown: handleInput
      }, null, 544), [
        [_vModelText, inputValue.value]
      ]),
      _createElementVNode("button", {
        class: "btn btn-success",
        onClick: handleButtonClick,
        disabled: !inputValue.value.trim()
      }, "Добавить регулярное выражение", 8, _hoisted_3),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(editStore).dictionary.regulars, (tag, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "tag"
        }, [
          _createElementVNode("span", null, _toDisplayString(tag), 1),
          _createElementVNode("a", {
            href: "#",
            onClick: _withModifiers(($event: any) => (removeTag(index)), ["prevent"])
          }, "×", 8, _hoisted_4)
        ]))
      }), 128))
    ])
  ], 64))
}
}

})