import {
	generateAsyncDataTableStore,
	definePromiseQueue,
	mapTableFiltersToSearchParams,
} from '@/components/Table';
import { getList, TModerationsSearchParams } from '../bff-service';  // Импорт функции для получения мок-данных
import { TModeratorsGetListResDto } from '../types';

export type TModeratorData = {
	id: number;
	telegram_user_id: string;
	is_super_moderator: boolean;
	description: string;
}

const allListPromiseQueue = definePromiseQueue<TModeratorsGetListResDto>();

export const useAsyncModeratorAllListStore = generateAsyncDataTableStore<TModeratorData>({
	initialState: {
			loading: true,
	},

	dataGetter: async (useStore) => {
		const store = useStore();

		await allListPromiseQueue.wait(allListGetter, useStore)
			.then(response=>{
				store.data = response.list.map( deal => {
					return {
						id: deal.id,
						telegram_user_id: deal.telegram_user_id,
						is_super_moderator: deal.is_super_moderator, 
						description: deal.description,
					};
				});
				store.paginateTotal = response.paginate.filtered;
				store.paginateFiltered = response.paginate.filtered;
			})
			.catch(e=>{
				store.data = [];
				store.paginateTotal = 0;
			})
			.finally(()=>{
				store.loading = false;
			})
	}
});


// получение списка заявок
const allListGetter = (): Promise<TModeratorsGetListResDto> => {
	const store = useAsyncModeratorAllListStore();
	
	// приводим данные фильтров стора таблиц 
	// к виду данных для поиска
	const search = mapTableFiltersToSearchParams<typeof store, TModerationsSearchParams>(
		store, // указываем нужный стор
		// указываем данные поиска по дефолту
		{
			telegram_user_id: '',
			description: '',
		},
		// указываем соответствие ключей строра таблиц к ключам данных для поиска
		{
			telegram_user_id: 'telegram_user_id',
			description: 'description',
		}
	);
	return getList(store.paginatePage, store.paginatePerPage, search);
}