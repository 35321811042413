import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, resolveComponent as _resolveComponent, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "col-lg-6" }
const _hoisted_2 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_7 = { class: "form-group form-check-no-margin" }
const _hoisted_8 = ["disabled"]

import { onMounted, ref, watch } from 'vue';
import { defineComponent } from 'vue';
import { createModerator, getList } from './bff-service'; // Импортируем функцию из BFF-сервиса.
// components
import {
    Card,
    CardTitle,
    MinWidthAndScroll,
    Table,
    TablePagination,
} from '@/components/kit';
import { EmptyTr, RowModerators as TableRow } from './components';
import AllModeratorsFilters from './components/AllModeratorsFilters.vue';
// store
import { useAsyncModeratorAllListStore } from './store';
import { isUserCanEditModerators } from '@/store';
import ModeratorEditTable from './components/ModeratorEditTable.vue';
import ModeratorCell from './components/ModeratorCell.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModeratorsEdit',
  setup(__props) {

const tableStore = useAsyncModeratorAllListStore();

tableStore.columns = [
  { key: 'id', title: '#' },
  { key: 'telegram_user_id', title: 'ID модератора', searchable: true, filterable: true },
  { key: 'description', title: 'Никнейм', searchable: true, filterable: true },
  { key: 'is_super_moderator', title: 'Супер', component: ModeratorCell },
  { key: 'edit', title: 'Редактирование', component: ModeratorEditTable },
];

tableStore.paginateInfoTpl = '_DATA_ из _TOTAL_';
tableStore.body = { emptyComponent: EmptyTr };
tableStore.head = { class: 'head-no-select' };
if (!tableStore.row) {
  tableStore.row = {};
}
tableStore.row.component = TableRow;

if (isUserCanEditModerators()) {
    tableStore.getData();
}

const isCreateModeratorVisible = ref(false);

// данные в инпутах при добавление модера
const newModerator = ref({
    channel_id: 'all',
    is_super_moderator: false,
    telegram_user_id: '', 
    description: '',
});

const errorMessages = ref({
    telegram_user_id: '',
    description: '',
});

const validateTelegramId = (event: Event) => {
  const input = event.target as HTMLInputElement;
  input.value = input.value.replace(/[^0-9]/g, ''); 
  newModerator.value.telegram_user_id = input.value;
};

const validateDescription = (event: Event) => {
  const input = event.target as HTMLInputElement;
  input.value = input.value.replace(/[^a-zA-Z0-9_@]/g, ''); 
  newModerator.value.description = input.value;
};

const moderatorsIds = ref<string[]>([]);

watch(
  () => tableStore.data, // При загрузке data не успевает подгружаться, следим за обновлением
  (newData) => {
    moderatorsIds.value = newData.map((moderator) => moderator.telegram_user_id);
  },
  { immediate: true } 
);

const validateFields = async () => {
  const errors = {
    telegram_user_id: '',
    description: '',
  };

  const responseById = await getList(1, 10, { telegram_user_id: newModerator.value.telegram_user_id });
  if (responseById && responseById.list.some(item => item.telegram_user_id === newModerator.value.telegram_user_id)){
    errors.telegram_user_id = 'Модератор с таким ID уже существует';
  }

  const responseByName = await getList(1, 10, { description: newModerator.value.description });
  if (responseByName && responseByName.list.some(item => item.description === newModerator.value.description)) {
    errors.description = 'Модератор с таким Именем уже существует';
  }

  errorMessages.value = errors;

  return !errors.telegram_user_id && !errors.description;
};

const createNewModerator = async () => {
    
    const isValid = await validateFields();
    if(!isValid) return;

    const response = await createModerator(newModerator.value);
    if(response){
        newModerator.value = {
            channel_id: 'all',
            is_super_moderator: false,
            telegram_user_id: '', 
            description: '',
        };
        isCreateModeratorVisible.value = false;
        tableStore.getData(); 
    } 
};

defineComponent({ name: 'AllModerationTable' });

return (_ctx: any,_cache: any) => {
  const _component_Row = _resolveComponent("Row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_unref(isUserCanEditModerators)())
      ? (_openBlock(), _createBlock(_unref(Card), {
          key: 0,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(CardTitle), null, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Нет прав на просмотр данных")
              ])),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(isUserCanEditModerators)())
      ? (_openBlock(), _createBlock(_unref(Card), {
          key: 1,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(CardTitle), null, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("Модераторы в канале")
              ])),
              _: 1
            }),
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("p", null, [
                _createElementVNode("button", {
                  class: "btn btn-inverse-primary btn-fw",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (isCreateModeratorVisible.value = !isCreateModeratorVisible.value))
                }, " Добавить модератора ")
              ]),
              (isCreateModeratorVisible.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _cache[6] || (_cache[6] = _createElementVNode("label", { for: "telegram_user_id" }, "ID Модератора", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        id: "telegram_user_id",
                        class: "form-control",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((newModerator.value.telegram_user_id) = $event)),
                        onInput: validateTelegramId,
                        placeholder: "Для ввода доступны только цифры"
                      }, null, 544), [
                        [_vModelText, newModerator.value.telegram_user_id]
                      ]),
                      (errorMessages.value.telegram_user_id)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(errorMessages.value.telegram_user_id), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _cache[7] || (_cache[7] = _createElementVNode("label", { for: "description" }, "Никнейм", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        id: "description",
                        class: "form-control",
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((newModerator.value.description) = $event)),
                        onInput: validateDescription,
                        placeholder: "Символы a-z, 0-9 и подчеркивания. Минимальная длина - 5 символов"
                      }, null, 544), [
                        [_vModelText, newModerator.value.description]
                      ]),
                      (errorMessages.value.description)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(errorMessages.value.description), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        id: "is_super_moderator",
                        class: "form-check-input",
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((newModerator.value.is_super_moderator) = $event))
                      }, null, 512), [
                        [_vModelCheckbox, newModerator.value.is_super_moderator]
                      ]),
                      _cache[8] || (_cache[8] = _createElementVNode("label", {
                        for: "is_super_moderator",
                        class: "form-check-label"
                      }, " Супермодератор ", -1))
                    ]),
                    _createElementVNode("button", {
                      class: "btn btn-success",
                      onClick: createNewModerator,
                      disabled: !newModerator.value.telegram_user_id.trim() || !newModerator.value.description.trim()
                    }, " Создать ", 8, _hoisted_8)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_Row, null, {
              default: _withCtx(() => [
                _createVNode(_unref(MinWidthAndScroll), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Table), { "use-store": _unref(useAsyncModeratorAllListStore) }, {
                      default: _withCtx(() => [
                        _createVNode(AllModeratorsFilters)
                      ]),
                      _: 1
                    }, 8, ["use-store"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(TablePagination), {
              class: "tab-content tab-transparent-content",
              "use-store": _unref(useAsyncModeratorAllListStore)
            }, null, 8, ["use-store"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})