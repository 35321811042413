import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, Fragment as _Fragment, createElementBlock as _createElementBlock, toHandlers as _toHandlers, mergeProps as _mergeProps, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = { class: "col-lg-6 textarea__faq-question" }
const _hoisted_3 = { class: "select-category faq-select" }
const _hoisted_4 = ["required"]
const _hoisted_5 = { class: "margin-top-faq" }
const _hoisted_6 = { class: "reference-row" }
const _hoisted_7 = { class: "reference-type-select faq-select" }
const _hoisted_8 = { class: "select-reference reference-select faq-select faq-reference-select" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "col-lg-6 textarea__faq-answer margin-top" }
const _hoisted_11 = { class: "char-counter" }
const _hoisted_12 = {
  key: 0,
  class: "btn btn-success btn-fw margin-top-faq"
}
const _hoisted_13 = {
  key: 1,
  class: "btn btn-success btn-fw margin-top-faq"
}

import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { isUserCanViewFaq, isUserCanEditFaq } from '@/store';
import PageHeader from '@/components/kit/PageHeader.vue';
import Loader from '@/components/Loader.vue';
import Card from '@/components/kit/Card.vue';
import CardTitle from '@/components/kit/CardTitle.vue';
import { routeNames, cmiStatuses } from "@/router";
import { useRoute } from 'vue-router';
import Select from '@/components/kit/Select.vue';
import SelectFaq from './components/SelectFaq.vue';
import { TSelectOption } from '@/components/kit/types';
import Row from "@/components/kit/Row.vue";
import FormGroup from "@/components/kit/FormGroup.vue";
// store
import { useFaqEditItemStore } from './store/FaqEditStore';
// api
import {
  createFaq,
  updateFaq,
  deleteFaq,
  getFaqCategories,
  getFaqReferences,
} from './bff-service';
// router
import { ENavy, router } from '@/router';



export default /*@__PURE__*/_defineComponent({
  __name: 'FaqQuestion',
  setup(__props) {

// components
const isDevMode = new URL(window.location.toString()).searchParams.has('_dev'); // Отладочная информация

// store
const store = useFaqEditItemStore();
const pageId = ref<number>(NaN);

const selectedType = ref<string>('_all'); 
const selectedCategory = ref<string[]>([]);
const selectedRefence = ref<string[]>([]); 
const route = useRoute();
const isCreating = ref(route.query.isCreating === 'true' || false);
const answerLength = computed(() => store.answer.length);

// категории и референсы
const categoryList = [] as {
  value: string;
  label: string;
}[];

const referencesList = [] as {
  value: string;
  label: string;
  path: string;
  type: string;
}[];

const referencesTypesList = [{
  value: '_all',
  label: 'Все'
}] as {
  value: string;
  label: string;
}[];


const fetchData = async () => {
  try {
    // получаем список категорий и референсов 
    const {
      0: resCategories, 
      1: resReferences
    } = await Promise.all([
      getFaqCategories(),
      getFaqReferences()
    ]);

    // заполняем категории
    resCategories.list.map(i=>{
      categoryList.push({
        value: i.id + '',
        label: i.name,
      })
    })

    // заполняем референсы
    resReferences.list.map(i=>{
      referencesList.push({
        value: i.id + '',
        label: i.reference_name,
        path: i.reference_path,
        type: i.reference_type
      })
    });

    // заполняем типы референсов
    var uniqReferenceTypes = new Set(resReferences.list.map(i=>i.reference_type + ''));
    [...uniqReferenceTypes.values()].forEach(i=>referencesTypesList.push({
      value: i,
      label: i
    }));

    // устанавливаем флаг загрузки данных страницы
    pageDataLoaded.value = true;
  } catch (error) {
    console.error(error);
    alert('Произошла ошибка, попробуйте перезагрузить страницу.');
  }
};

onMounted(() => {
  fetchData();
  if(!isCreating.value){
    const route = useRoute();
    const pageID = parseInt((route?.params?.id ?? '') + '', 10);
    if (Number.isInteger(pageID)) {
      pageId.value = pageID;
      store.getDataById(pageID);
    }
  }
});

onUnmounted(() => {
  if (!isCreating.value) {
    store.$reset();
    selectedType.value = '';
  }
});


// ожидание загрузки страницы и всех данных
const pageLoaded = ref<boolean>(false);
const pageDataLoaded = ref<boolean>(false);

watch(
  [
    pageDataLoaded,
    ()=>store.$loading
  ],

  ({0: dataLoaded, 1: storeLoading})=>{

    if (dataLoaded && (!storeLoading || isCreating.value)) {

      // подготавливаем референсы
      var faq_references = store.faq_references;
      if (Array.isArray(faq_references)) {
        selectedRefence.value = Array.from(new Set([
          ...selectedRefence.value,
          ...faq_references.map(i => i.id + '')
        ]));
      }

      // подготавливаем категории
      var faq_categories = store.faq_categories;
      if (Array.isArray(faq_categories)) {
        selectedCategory.value = Array.from(new Set([ 
          ...selectedCategory.value,
          ...faq_categories.map(i => i.id + '')
        ]));
      }

      pageLoaded.value = true
    }
  }
);

// кастомный фильтр для референсов
const customReferencesFilter = (options: TSelectOption[], search: string) => {
  const regexp = new RegExp(search, 'i');

  return options.filter((item) => {
    // фильтр по типу
    var referenceType = selectedType.value;
    if (referenceType && referenceType !== '_all') {
      if ( 
        (item as typeof referencesList[number]).type !== referenceType
      ) {
        return false;
      }
    }

    // фильтр по значению
    return regexp.test(item.label) 
    || regexp.test(item.value) 
    || regexp.test((item as typeof referencesList[number]).path)
  })
}

// сохранение и добавление

const submitFormHandler = (e: Event)=>{
  e.preventDefault();
  e.stopPropagation();

  const requestData = {
    question_text: store.question_text,
    answer: store.answer,
    categories: [...selectedCategory.value].map(Number),
    references: [...selectedRefence.value].map(Number),
  };
  
  if (isCreating.value) {
    // создание
    createFaq(requestData)
    .then((response)=>{
      if (response.status) {
        router.push(ENavy.faqQuestions);
      }
      else {
        alert('Произошла ошибка.\nПопробуйте сохранить ещё раз.');
      }
    })
    .catch((e)=>{
      console.error(e);
      alert('Произошла ошибка.\nПопробуйте сохранить ещё раз.');
    })
  }
  else {
    // редактирование
    updateFaq(pageId.value, requestData)
    .then((response)=>{
      if (response.status) {
        router.push(ENavy.faqQuestions);
      }
      else {
        alert('Произошла ошибка.\nПопробуйте сохранить ещё раз.');
      }
    })
    .catch((e)=>{
      console.error(e);
      alert('Произошла ошибка.\nПопробуйте сохранить ещё раз.');
    })
  }
}

// удаление
const deleteButtonHandler = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();

  if (!pageId.value) { return }

  var isApproved = confirm('Удалить вопрос?');

  if (!isApproved) { return }

  deleteFaq(pageId.value)
  .then((response)=>{
      if (response.status) {
        router.push(ENavy.faqQuestions);
      }
      else {
        alert('Произошла ошибка.\nПопробуйте удалить ещё раз.');
      }
    })
    .catch((e)=>{
      console.error(e);
      alert('Произошла ошибка.\nПопробуйте удалить ещё раз.');
    })
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageHeader, null, {
      default: _withCtx(() => _cache[5] || (_cache[5] = [
        _createTextVNode("Редактирование FAQ")
      ])),
      _: 1
    }),
    (!_unref(isUserCanViewFaq)())
      ? (_openBlock(), _createBlock(Card, {
          key: 0,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            _createVNode(CardTitle, null, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("Нет прав на просмотр данных")
              ])),
              _: 1
            })
          ]),
          _: 1
        }))
      : (_unref(isUserCanEditFaq)() && (pageId.value || isCreating.value))
        ? (_openBlock(), _createBlock(Card, {
            key: 1,
            style: {"position":"relative"}
          }, {
            default: _withCtx(() => [
              (!pageLoaded.value)
                ? (_openBlock(), _createBlock(Loader, { key: 0 }))
                : (_unref(store).$error)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _cache[11] || (_cache[11] = _createElementVNode("h3", null, "Произошла ошибка получения данных.", -1)),
                      _cache[12] || (_cache[12] = _createElementVNode("p", null, [
                        _createElementVNode("strong", null, "Попробуйте перезагрузить страницу"),
                        _createTextVNode(", если это не поможет "),
                        _createElementVNode("strong", null, "обратитесь в поддержку"),
                        _createTextVNode(".")
                      ], -1)),
                      _cache[13] || (_cache[13] = _createElementVNode("p", null, [
                        _createElementVNode("strong", null, "Данные об ошибке.")
                      ], -1)),
                      _createElementVNode("p", null, [
                        _cache[7] || (_cache[7] = _createElementVNode("strong", null, "Обозначение ошибки: ", -1)),
                        _createTextVNode(_toDisplayString(_unref(store).$error.error), 1)
                      ]),
                      _createElementVNode("p", null, [
                        _cache[8] || (_cache[8] = _createElementVNode("strong", null, "ID запроса: ", -1)),
                        _createTextVNode(_toDisplayString(_unref(store).$error.requestId), 1)
                      ]),
                      _createElementVNode("p", null, [
                        _cache[9] || (_cache[9] = _createElementVNode("strong", null, "Код ответа: ", -1)),
                        _createTextVNode(_toDisplayString(_unref(store).$error.code ?? ''), 1)
                      ]),
                      _cache[14] || (_cache[14] = _createElementVNode("p", null, " ", -1)),
                      _createVNode(_component_router_link, {
                        to: { name: _unref(routeNames).faqQuestions, params: { status: _unref(cmiStatuses).all } }
                      }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode("Вернуться к списку вопросов.")
                        ])),
                        _: 1
                      }, 8, ["to"])
                    ], 64))
                  : (pageLoaded.value)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        (_unref(isDevMode))
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _cache[15] || (_cache[15] = _createElementVNode("p", null, "только для отладки:", -1)),
                              _createElementVNode("textarea", {
                                value: JSON.stringify(_unref(store), null, `\t`),
                                class: "form-control form-control-sm",
                                style: {"resize":"vertical"}
                              }, null, 8, _hoisted_1),
                              _cache[16] || (_cache[16] = _createElementVNode("p", null, null, -1))
                            ], 64))
                          : _createCommentVNode("", true),
                        _createVNode(CardTitle, null, {
                          default: _withCtx(() => _cache[17] || (_cache[17] = [
                            _createTextVNode("Редактирование данных")
                          ])),
                          _: 1
                        }),
                        _createElementVNode("form", { onSubmit: submitFormHandler }, [
                          _createVNode(FormGroup, null, {
                            default: _withCtx(() => [
                              _createVNode(Row, { class: "tab-content tab-transparent-content" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_2, [
                                    _createElementVNode("div", _hoisted_3, [
                                      _cache[18] || (_cache[18] = _createElementVNode("label", {
                                        class: "key-label",
                                        for: "category-list"
                                      }, "Категория", -1)),
                                      (pageDataLoaded.value)
                                        ? (_openBlock(), _createBlock(SelectFaq, {
                                            key: 0,
                                            id: "category-list",
                                            options: categoryList,
                                            multiple: true,
                                            modelValue: selectedCategory.value,
                                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedCategory).value = $event))
                                          }, {
                                            search: _withCtx(({ attributes, events }) => [
                                              _createElementVNode("input", _mergeProps({
                                                class: "vs__search",
                                                required: !selectedCategory.value.length
                                              }, attributes, _toHandlers(events, true)), null, 16, _hoisted_4)
                                            ]),
                                            _: 1
                                          }, 8, ["modelValue"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _cache[22] || (_cache[22] = _createElementVNode("label", {
                                      class: "key-label margin-top-faq",
                                      for: "faq-question"
                                    }, "Вопрос", -1)),
                                    _withDirectives(_createElementVNode("textarea", {
                                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(store).question_text) = $event)),
                                      id: "faq-question",
                                      class: "form-control form-control-sm",
                                      style: {"resize":"vertical"},
                                      required: ""
                                    }, null, 512), [
                                      [_vModelText, _unref(store).question_text]
                                    ]),
                                    _createElementVNode("div", _hoisted_5, [
                                      _createElementVNode("div", _hoisted_6, [
                                        _createElementVNode("div", _hoisted_7, [
                                          _cache[19] || (_cache[19] = _createElementVNode("label", {
                                            class: "key-label",
                                            for: "ref-type-filter"
                                          }, "Тип референса", -1)),
                                          (pageDataLoaded.value)
                                            ? (_openBlock(), _createBlock(Select, {
                                                key: 0,
                                                id: "ref-type-filter",
                                                options: referencesTypesList,
                                                modelValue: selectedType.value,
                                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((selectedType).value = $event))
                                              }, null, 8, ["modelValue"]))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _createElementVNode("div", _hoisted_8, [
                                          _cache[21] || (_cache[21] = _createElementVNode("label", {
                                            class: "key-label",
                                            for: "refereces-list"
                                          }, "Референсы", -1)),
                                          _createVNode(SelectFaq, {
                                            id: "refereces-list",
                                            options: referencesList,
                                            modelValue: selectedRefence.value,
                                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((selectedRefence).value = $event)),
                                            multiple: true,
                                            label: 'reference_name',
                                            "custom-filter": customReferencesFilter
                                          }, {
                                            option: _withCtx(({option}) => [
                                              (selectedType.value==='_all')
                                                ? (_openBlock(), _createElementBlock("div", {
                                                    key: 0,
                                                    class: _normalizeClass(['faq-ref-option', `faq-ref-${option.type}`])
                                                  }, _toDisplayString(option.label), 3))
                                                : (option.type === selectedType.value)
                                                  ? (_openBlock(), _createElementBlock("div", {
                                                      key: 1,
                                                      class: _normalizeClass(['faq-ref-option', `faq-ref-${option.type}`])
                                                    }, _toDisplayString(option.label), 3))
                                                  : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                                      _createTextVNode(_toDisplayString(''))
                                                    ], 64))
                                            ]),
                                            selectedOption: _withCtx(({ option, deselect }) => [
                                              _createElementVNode("span", {
                                                class: _normalizeClass(['vs__selected faq-ref-selected-option', `faq-ref-${option.type}` ])
                                              }, [
                                                _createTextVNode(_toDisplayString(option.label) + " ", 1),
                                                _createElementVNode("button", {
                                                  type: "button",
                                                  class: "vs__deselect",
                                                  onClick: ($event: any) => (deselect(option))
                                                }, _cache[20] || (_cache[20] = [
                                                  _createElementVNode("svg", {
                                                    xmlns: "http://www.w3.org/2000/svg",
                                                    width: "10",
                                                    height: "10"
                                                  }, [
                                                    _createElementVNode("path", { d: "M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z" })
                                                  ], -1)
                                                ]), 8, _hoisted_9)
                                              ], 2)
                                            ]),
                                            _: 1
                                          }, 8, ["modelValue"])
                                        ])
                                      ])
                                    ])
                                  ]),
                                  _createElementVNode("div", _hoisted_10, [
                                    _cache[23] || (_cache[23] = _createElementVNode("label", {
                                      class: "key-label",
                                      for: "faq-answer"
                                    }, "Ответ", -1)),
                                    _withDirectives(_createElementVNode("textarea", {
                                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(store).answer) = $event)),
                                      id: "faq-answer",
                                      class: "form-control form-control-sm",
                                      style: {"resize":"vertical"},
                                      required: ""
                                    }, null, 512), [
                                      [_vModelText, _unref(store).answer]
                                    ]),
                                    _createElementVNode("span", _hoisted_11, "Введено символов: " + _toDisplayString(answerLength.value), 1)
                                  ])
                                ]),
                                _: 1
                              }),
                              (isCreating.value)
                                ? (_openBlock(), _createElementBlock("button", _hoisted_12, "Сохранить"))
                                : (_openBlock(), _createElementBlock("button", _hoisted_13, "Сохранить изменения"))
                            ]),
                            _: 1
                          })
                        ], 32),
                        (!isCreating.value)
                          ? (_openBlock(), _createBlock(FormGroup, { key: 1 }, {
                              default: _withCtx(() => [
                                _createElementVNode("button", {
                                  type: "button",
                                  class: "btn btn-danger btn-icon-text faq-btn-danger",
                                  onClick: deleteButtonHandler
                                }, _cache[24] || (_cache[24] = [
                                  _createElementVNode("i", { class: "fa fa-trash-o" }, null, -1),
                                  _createTextVNode(" Удалить вопрос ")
                                ]))
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ], 64))
                    : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
  ], 64))
}
}

})