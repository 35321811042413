import { defineStore } from 'pinia';
import { TBffResponseError, getDictionary, updateDictionary } from '../bff-service';


type TDictionaryEditItemStore = {
	// system
	$loading: boolean;
	$error?: TBffResponseError;
	// data
	id: number;
	dictionary_name: string;
	dictionary: Record<string,any>;
  isUpdated: boolean;
}

// store
export const useDictionaryEditItemStore = defineStore({
	id: 'dictionary-item',

	state: (): TDictionaryEditItemStore => ({
		$loading: true,
		$error: void 0,
		// data
		id: NaN,
		dictionary_name: '',
		dictionary: {},
		// @dev: 
		isUpdated: false,
	}),

	actions: {
		getDataById(dealId: number) {
			this.$loading = true;
			this.id = NaN;

			getDictionary(dealId)
				.then((responseData) => {
					this.$error = void 0;
					// Пример установки значений store
					this.id = responseData.id;
					this.dictionary_name = responseData.dictionary_name;
					this.dictionary = responseData.dictionary;

					this.isUpdated = false;
				})
				.catch((e: TBffResponseError) => {
					this.$error = e;
				})
				.finally(() => {
					this.$loading = false;
				});
		},

		updateData(data: string) {
			this.$loading = true;

			const dealId = this.id;

			updateDictionary(dealId, data)
				.then(() => {
					this.$error = void 0;
				})
				.catch((e: TBffResponseError) => {
					this.$error = e;
				})
				.finally(() => {
					this.$loading = false;
				});
		},

	},
});