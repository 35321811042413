<script setup lang="ts">
import { ref, defineComponent, onMounted, watch } from 'vue';
import {
  Card,
  CardTitle,
  Row,
  MinWidthAndScroll,
  Table,
  TablePagination,
} from '@/components/kit';
import AllChannelsFilters from './components/AllChannelsFilters.vue';
import { BlackListDeleteUser, EmptyTrBlackList, EmptyTrChannels, RowModerators as TableRow, RowBlackList as TableRowBlackList, } from './components/';
import { useAsyncBlackListAllListStore, useAsyncChannelAllListStore } from './store/';
import { isUserCanEditChannel, isUserCanViewChannels } from '@/store';
import { createChannel, getChannelsList } from './bff-service'; // Добавьте getChannelsList
import ChannelDelete from './components/ChannelDelete.vue';

// Store
const tableStore = useAsyncChannelAllListStore();
const tableStoreBlackList = useAsyncBlackListAllListStore();

tableStoreBlackList.columns = [
  { key: 'id', title: '#' },
  { key: 'user_id', title: 'ID пользователя', searchable: true, filterable: true},
  { key: 'username', title: 'Имя пользователя', searchable: true, filterable: true},
  { key: 'nickname', title: 'Никнейм', searchable: true, filterable: true},
  { key: 'edit', title: 'Удалить пользователя', component: BlackListDeleteUser },
];

tableStoreBlackList.paginateInfoTpl = '_DATA_ из _TOTAL_';
tableStoreBlackList.body = { emptyComponent: EmptyTrBlackList };
tableStoreBlackList.head = { class: 'head-no-select' };
if (!tableStoreBlackList.row) {
  tableStoreBlackList.row = {};
}
tableStoreBlackList.row.component = TableRowBlackList;

const searchQuery = ref('');
const isSearchInitiated = ref(false);

watch( 
  () => searchQuery.value, 
  (newVal) => {
    if (newVal && !isSearchInitiated.value) {
      tableStoreBlackList.searchBy(searchQuery.value.toLocaleLowerCase());
    }
  }
);

const handleSearch = () => {
  if (searchQuery.value) {
    isSearchInitiated.value = true;
    tableStoreBlackList.searchBy(searchQuery.value.toLocaleLowerCase());
  } else {
    isSearchInitiated.value = false;
    tableStoreBlackList.clearFilters();
  }
};

const closeblackList = () => {
  isSearchInitiated.value = false;
  searchQuery.value = '';
};  


tableStore.columns = [
  {
    key: 'id',
    title: '#',
  },
  {
    key: 'channel_id',
    title: 'ID чата',
    searchable: true,
    filterable: true,
  },
  {
    key: 'name',
    title: 'Имя чата',
    searchable: true,
    filterable: true,
  },
  { key: 'edit',
    title: 'Удаление чата',
    component: ChannelDelete
  },
];
tableStore.paginateInfoTpl = '_DATA_ из _TOTAL_';
tableStore.body = { emptyComponent: EmptyTrChannels };
tableStore.head = { class: 'head-no-select' };
if (!tableStore.row) {
  tableStore.row = {};
}
tableStore.row.component = TableRow;

onMounted(() => {
  tableStoreBlackList.clearFilters();
  if (isSearchInitiated.value) {
    tableStoreBlackList.getData();
  }
  tableStore.clearFilters();
  tableStore.getData();
});

if (isUserCanViewChannels()) {
  tableStore.getData();
}

const showChannelForm = ref(false);
const newChannel = ref({
  channel_id: '',
  name: '',
});

const errorMessages = ref({
  channel_id: '',
  name: '',
});

const toggleChannelForm = () => {
  showChannelForm.value = !showChannelForm.value;
};

const validateChannelId = (event: Event) => {
  const input = event.target as HTMLInputElement;
  input.value = input.value.replace(/[^0-9-]/g, ''); 
  newChannel.value.channel_id = input.value;
};

const validateChannelName = (event: Event) => {
  const input = event.target as HTMLInputElement;
  input.value = input.value.replace(/[^a-zA-Zа-яА-Я0-9_@]/g, ''); 
  newChannel.value.name = input.value;
};

const validateChannel = async () => {
  const errors = {
    channel_id: '',
    name: '',
  };

  const response = await getChannelsList(1, 10, { channel_id: newChannel.value.channel_id });
  if (response && response.list.some(item => item.channel_id === newChannel.value.channel_id)) {
    errors.channel_id = 'Чат с таким ID уже существует';
  }

  const responseByName = await getChannelsList(1, 10, { name: newChannel.value.name });
  if (responseByName && responseByName.list.some(item => item.name === newChannel.value.name)) {
    errors.name = 'Чат с таким Именем уже существует';
  }

  errorMessages.value = errors;

  return !errors.channel_id && !errors.name;
};

const submitChannel = async () => {

  const isValid = await validateChannel();
  if (!isValid) return;

  const response = await createChannel(newChannel.value);
  if (response) {
    newChannel.value = {
      channel_id: '',
      name: ''
    };
    showChannelForm.value = false;
    tableStore.getData();
  }
};

defineComponent({ name: 'AllChannelsTable' });
</script>

<template>
  <Card v-if="!isUserCanViewChannels()" style="position:relative">
    <CardTitle>Нет прав на просмотр данных</CardTitle>
  </Card>

  <Card v-if="isUserCanEditChannel()" style="position:relative">
    <CardTitle>Чёрный список</CardTitle>
    <div class="col-lg-6">
      <Row>
        <div class="channel-form mb-4">
          <label class="key-label margin-top channel-label">
            Поиск пользователей по его Id, Имени аккаунта или Имени пользователя (@имя)
          </label>
          <div class="black-list-search-row">
            <input
              class="margin-top form-control"
              placeholder="Поиск"
              v-model="searchQuery"
            />
            <button
              class="btn btn-success btn-icon"
              title="Поиск"
              @click="handleSearch"
              :disabled="!searchQuery"
            >
              <i class="mdi mdi-account-search"></i>
            </button>
            <button
              class="btn btn-outline-primary btn-icon"
              title="Закрыть"
              @click="closeblackList"
            >
              <i class="mdi mdi-close"></i>
            </button>
          </div>
        </div>
      </Row>
    </div>
    <Row v-if="isSearchInitiated">
      <CardTitle>Найденные пользователи</CardTitle>
      <MinWidthAndScroll>
        <Table :use-store="useAsyncBlackListAllListStore" />
      </MinWidthAndScroll>
    </Row>
    <TablePagination
      v-if="isSearchInitiated"
      class="tab-content tab-transparent-content"
      :use-store="useAsyncBlackListAllListStore"
    />
    <CardTitle>Список чатов</CardTitle>
    <div class="col-lg-6">
      <p>
        <button class="btn btn-inverse-primary btn-fw" @click="toggleChannelForm">
        Добавление чата
        </button>
      </p>

      <Row>
        <div v-if="showChannelForm" class="channel-form mb-4">
          <label class="key-label margin-top channel-label">Введите ID чата</label>
          <input 
            class="margin-top form-control" 
            @input=validateChannelId 
            v-model="newChannel.channel_id" 
            placeholder="ID (Цифры и знак минуса)" 
          />
          <p v-if="errorMessages.channel_id" class="error-message">{{ errorMessages.channel_id }}</p>
          <label class="key-label margin-top channel-label">Введите наименование чата</label>
          <input 
            class="margin-top form-control" 
            @input=validateChannelName 
            v-model="newChannel.name" 
            placeholder="Имя чата (Кирилица, Латиница, цифры и знаки @ _" 
          />
          <p v-if="errorMessages.name" class="error-message">{{ errorMessages.name }}</p>
          <button class="btn btn-success margin-both" @click="submitChannel" :disabled="!newChannel.channel_id.trim() || !newChannel.name.trim()">
            Создать
          </button>
        </div>
      </Row>

    </div>
    <Row>
      <MinWidthAndScroll>
        <Table :use-store="useAsyncChannelAllListStore">
          <AllChannelsFilters />
        </Table>
      </MinWidthAndScroll>
    </Row>

    <TablePagination class="tab-content tab-transparent-content" :use-store="useAsyncChannelAllListStore" />
  </Card>
</template>

<style>
.black-list-search-row input{
  width: 70%;
}
.black-list-search-row{
  display: flex;
  align-items: baseline;
  column-gap: 10px;
}
.channel-label{
  color: #3a3a3a;
}
.margin-top{
  margin-top: 8px;
}
.margin-both{
  margin: 8px auto;
}
.error-message {
  color: red;
  font-size: 12px;
}
</style>
