<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { defineComponent } from 'vue';
// import { createModerator, getList } from './bff-service'; // Импортируем функцию из BFF-сервиса.
// components
import {
    Card,
    CardTitle,
    MinWidthAndScroll,
    Table,
    TablePagination
} from '@/components/kit';
import { EmptyTr, Row as TableRow } from './components';
import AllCategoriesFilters from './components/AllCategoriesFilters.vue';
// store
import { useAsyncCategoryAllListStore } from './store';
import { isUserCanCategories, isUserCanEditCategories } from '@/store';
import CategoryEditTable from './components/CategoryEditTable.vue';
import { createCategory, getFaqCategory } from './bff-service';
import InputCategoryDesc from './components/InputCategoryDesc.vue';
import InputCategoryName from './components/InputCategoryName.vue';

const tableStore = useAsyncCategoryAllListStore();

tableStore.columns = [
  { key: 'id', title: '#' },
  { key: 'name', title: 'Категория', searchable: true, filterable: true, component: InputCategoryName },
  { key: 'description', title: 'Описание', searchable: true, filterable: true, component: InputCategoryDesc },
];

if(isUserCanEditCategories()) {
  tableStore.columns.push({ key: 'edit', title: 'Редактирование', component: CategoryEditTable });
}

tableStore.paginateInfoTpl = '_DATA_ из _TOTAL_';
tableStore.body = { emptyComponent: EmptyTr };
tableStore.head = { class: 'head-no-select' };
if (!tableStore.row) {
  tableStore.row = {};
}
tableStore.row.component = TableRow;

if (isUserCanCategories()) {
    tableStore.getData();
}

const isCreateCategoryVisible = ref(false);

// данные в инпутах при добавление модера
const newCategory = ref({
    name: '',
    description: '',
});

const errorMessages = ref({
    name: '',
});

const categories = ref<string[]>([]);

watch(
  () => tableStore.data, // При загрузке data не успевает подгружаться, следим за обновлением
  (newData) => {
    categories.value = newData.map((category) => category.name);
  },
  { immediate: true } 
);

const validateFields = async () => {
  const errors = {
    name: '',
  };

  const responseByName = await getFaqCategory(1, 10, { name: newCategory.value.name, description: '' });
  if (responseByName && responseByName.list.some(item => item.name.toLocaleLowerCase() === newCategory.value.name.toLocaleLowerCase())){
    errors.name = 'Категория с таким именем уже есть';
  }

  errorMessages.value = errors;

  return !errors.name;
};

const createNewCategory = async () => {
    
    const isValid = await validateFields();
    if(!isValid) return;

    const response = await createCategory(newCategory.value);
    if(response){
      newCategory.value = {
            name: '', 
            description: '',
        };
        isCreateCategoryVisible.value = false;
        tableStore.getData(); 
    } 
};

defineComponent({ name: 'AllCategoryTable' });
</script>

<template>
  <Card v-if="!isUserCanCategories()" style="position:relative">
    <CardTitle>Нет прав на просмотр данных</CardTitle>
  </Card>

  <Card v-if="isUserCanCategories()" style="position:relative">
    <CardTitle>Категории FAQ</CardTitle>
    <div v-if="isUserCanEditCategories()" class="col-lg-6">
      <p>
        <button
          class="btn btn-inverse-primary btn-fw"
          @click="isCreateCategoryVisible = !isCreateCategoryVisible"
        >
          Добавить категорию
        </button>
      </p>

      <div v-if="isCreateCategoryVisible" class="mb-4">
        <div class="form-group">
          <label for="telegram_user_id">Наименование категории</label>
          <input
            type="text"
            id="telegram_user_id"
            class="form-control"
            v-model.trim="newCategory.name"
            placeholder="Введите название"
          />
          <span v-if="errorMessages.name" class="text-danger">
            {{ errorMessages.name }}
          </span>
        </div>
        <div class="form-group">
          <label for="description">Описание категории</label>
          <input
            type="text"
            id="description"
            class="form-control"
            v-model.trim="newCategory.description"
            placeholder="Введите описание"
          />
        </div>
        <button class="btn btn-success" @click="createNewCategory" :disabled="!newCategory.name.trim() || !newCategory.description.trim()">
          Создать
        </button>
      </div>
    </div>

    <Row>
      <MinWidthAndScroll>
        <Table :use-store="useAsyncCategoryAllListStore">
          <AllCategoriesFilters />
        </Table>
      </MinWidthAndScroll>
    </Row>

    <TablePagination
      class="tab-content tab-transparent-content"
      :use-store="useAsyncCategoryAllListStore"
    />
  </Card>
</template>

<style>
.text-danger {
  color: red;
  font-size: 0.875rem;
}

.form-group input::placeholder {
  opacity: 0.6;
}

.form-check-no-margin{
  position: relative;
  margin-top: 15px;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
}

.form-check-no-margin .form-check-input{
  float: left;
  margin-left: -1.5em;
}

.form-check-no-margin .form-check-label{
  display: block;
  margin-left: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
</style>