import { TBlackListGetListResDto, TChannelItemResDto, TChannelPostedGetItemResDto, TChannelsGetListResDto, TDictionaryItemResDto, TModeratorPostedGetItemResDto, TModeratorsGetListResDto } from './types';
export { 
	
} from './types';
import { del, get, post, put } from '@/service/bffService';
export { TBffResponseError } from '@/service/bffService';

export type TChannelsSearchParams = {
	channel_id?: string;
	name?: string;
}

export type TModerationsSearchParams = {
	telegram_user_id?: string;
	description?: string;
}

export type TDictionariesSearchParams = {
	dictionary_name?: string;
}

export type TBlacksSearchParams = {
	user_id: string;
	username: string;
  nickname: string;
}

const mockBlackListData = Array.from({ length: 50 }, (_, index) => ({
  id: index + 1,
  user_id: `user_${index + 1}`,
  username: `Username ${index + 1}`,
  nickname: `Nickname ${index + 1}`
}));

// Channels
export const getChannelsList = async (page: number, perPage: number, search?: TChannelsSearchParams): Promise<TChannelsGetListResDto> => {
	const searchParams = Object.entries(search || {}).map(filter=>`${filter[0]}=${encodeURI(filter[1])}`).join('&');

	return get<TChannelsGetListResDto>(`/api/tg-pannel-channels?perPage=${perPage}&page=${page}&${searchParams}`);
}

export const getChannel = async (id: number): Promise<TChannelItemResDto> => {
	return get<TChannelItemResDto>('/api/tg-panel-channels/' + id);
}

export const updateChannel = async (id: number, data: string): Promise<any> => {
	return put<any, string>('/api/tg-panel-channels/' + id, data);
}

export const createChannel = async (data: TChannelPostedGetItemResDto): Promise<TChannelPostedGetItemResDto> => {
  return post('/api/tg-pannel-channels', data);
};

export const deleteChannel = async (id: number): Promise<any> => {
	return del<any>(`/api/tg-pannel-channels/` + id);
};


// moderator
export const getList = async (page: number,perPage: number,search?: TModerationsSearchParams): Promise<TModeratorsGetListResDto> => {
	const searchParams = Object.entries(search || {}).map(filter=>`${filter[0]}=${encodeURI(filter[1])}`).join('&');

	return get<TModeratorsGetListResDto>(`/api/tg-pannel-moderators?perPage=${perPage}&page=${page}&${searchParams}`);
};

export const createModerator = async (data: TModeratorPostedGetItemResDto): Promise<TModeratorPostedGetItemResDto> => {
  return post('/api/tg-pannel-moderators', data);
};

export const updateModerator = async (id: number, data: object): Promise<any> => {
	return put<any, object>('/api/tg-pannel-moderators/' + id, data);
}

export const deleteModerator = async (id: number): Promise<any> => {
	return del<any>(`/api/tg-pannel-moderators/` + id);
};


// Dictionary
export const getDictionary = async (id: number): Promise<TDictionaryItemResDto> => {
	return get<TDictionaryItemResDto>('/api/tg-panel-dictionaries/' + id);
}

export const updateDictionary = async (id: number, data: string): Promise<any> => {
	return put<any, string>('/api/tg-panel-dictionaries/' + id, data);
}

// Black list
export const getBlackList = async (
  page: number,
  perPage: number,
  search?: string,
): Promise<TBlackListGetListResDto> => {

  let filteredData = mockBlackListData;

  if (search) {
    filteredData = filteredData.filter((item) => {
      return Object.values(item).some((value) =>
        value?.toString().toLowerCase().includes(search.toLowerCase())
      );
    });
  }

  const start = (page - 1) * perPage;
  const paginatedData = filteredData.slice(start, start + perPage);

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        list: paginatedData,
        paginate: {
          total: mockBlackListData.length,
          filtered: filteredData.length,
          page,
          perPage,
        },
      });
    }, 300);
  });
};

export const deleteUserFromBlackList = async (id: number): Promise<any> => {
	return del<any>(`/api/black-list/` + id);
};