import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import NewYearTree from './NewYearTree.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Informer',
  setup(__props) {

const dateNow = new Date(); 
const date = dateNow.getDate();
const month = dateNow.getMonth();

const isNewYear = (month === 0 && date < 12) || (month === 11 && date > 11);

return (_ctx: any,_cache: any) => {
  return (_unref(isNewYear))
    ? (_openBlock(), _createBlock(NewYearTree, { key: 0 }))
    : _createCommentVNode("", true)
}
}

})