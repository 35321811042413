import {
	generateAsyncDataTableStore,
	definePromiseQueue,
	mapTableFiltersToSearchParams,
	useTableSearch,
	TAsyncDataTableStore,
} from '@/components/Table';
import { getBlackList, TBlacksSearchParams } from '../bff-service';  // Импорт функции для получения мок-данных
import { TBlackListGetListResDto,  } from '../types';
import { useAsyncChannelAllListStore } from './ChannelsListStore';

export type TBlackListData = {
	id: number;
	user_id: string;
	username: string;
	nickname: string;
}

const allListPromiseQueue = definePromiseQueue<TBlackListGetListResDto>();

export const useAsyncBlackListAllListStore = generateAsyncDataTableStore<TBlackListData>({
	initialState: {
			loading: true,
	},

	dataGetter: async (useStore) => {
		const store = useStore();

		await allListPromiseQueue.wait(allListGetter, useStore)
			.then(response=>{
				store.data = response.list.map( deal => {
					return {
						id: deal.id,
						user_id: deal.user_id,
						username: deal.username, 
						nickname: deal.nickname,
					};
				});
				store.paginateTotal = response.paginate.filtered;
				store.paginateFiltered = response.paginate.filtered;
			})
			.catch(e=>{
				store.data = [];
				store.paginateTotal = 0;
			})
			.finally(()=>{
				store.loading = false;
			})
	}
});

// Получение черного списка
export const allListGetter = () => {
	const store = useAsyncBlackListAllListStore();

  return getBlackList(store.paginatePage, store.paginatePerPage, store.search);
}