<script setup lang="ts">
import { TCategoryData } from '../store';

const props = defineProps<{ rowData: TCategoryData }>();
const { rowData } = props;

import { ref } from 'vue';
import { getFaqCategory } from '../bff-service';

const errorMessage = ref<string>(''); 

const validateName = async (name: string, currentId: number) => {
  const responseByName = await getFaqCategory(1, 10, { name, description: '' });
  // Проверяем, есть ли совпадение по имени, игнорируя текущую категорию
  if (
    responseByName &&
    responseByName.list.some(
      (item) => item.name.toLowerCase() === name.toLowerCase() && item.id !== currentId
    )
  ) {
    errorMessage.value = 'Такая категория уже есть.';
    return false;
  }
  errorMessage.value = ''; // Сбрасываем ошибку, если всё ок
  return true;
};

</script>

<template>
  <td>
    <div v-if="!rowData.isEditing" class="d-flex gap-2 align-items-center">
      <span v-text="rowData.name" />
    </div>

    <div v-else class="d-flex flex-column gap-2 align-items-start">
      <input 
        class="category-input-table"
        :class="{ 'is-invalid': errorMessage }"
        v-model.trim="rowData.name"
        @blur="validateName(rowData.name, rowData.id)" 
      />
      <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span>
    </div>
  </td>
</template>