import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title"]

import { ref } from 'vue';
import { ITableCellProps } from '@/components/Table/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'QuestionTd',
  props: {
    dataKey: {},
    rowData: {},
    rowIndex: {},
    value: {},
    class: {}
  },
  setup(__props: any) {

const props = __props;
const question = props.value;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("td", {
    class: "table__td-faq-question",
    title: _unref(question)
  }, [
    _createElementVNode("p", null, _toDisplayString(_unref(question)), 1)
  ], 8, _hoisted_1))
}
}

})