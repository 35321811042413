<script setup lang="ts">
import { defineProps, ref } from 'vue';
import { ITableCellProps } from '@/components/Table/types';

const props = defineProps<ITableCellProps>();
const question = props.value;
</script>

<template>
  <td class="table__td-faq-question" :title="question" >
    <p>{{ question }}</p>
  </td>
</template>

<style >
.table__td-faq-question p{
  width: 600px;
  white-space: pre-wrap;
  overflow: hidden;
  margin: 0;
}
</style>