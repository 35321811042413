<script setup lang="ts">
// components
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { isUserCanViewFaq, isUserCanEditFaq } from '@/store';
import PageHeader from '@/components/kit/PageHeader.vue';
import Loader from '@/components/Loader.vue';
import Card from '@/components/kit/Card.vue';
import CardTitle from '@/components/kit/CardTitle.vue';
import { routeNames, cmiStatuses } from "@/router";
import { useRoute } from 'vue-router';
import Select from '@/components/kit/Select.vue';
import SelectFaq from './components/SelectFaq.vue';
import { TSelectOption } from '@/components/kit/types';
import Row from "@/components/kit/Row.vue";
import FormGroup from "@/components/kit/FormGroup.vue";
// store
import { useFaqEditItemStore } from './store/FaqEditStore';
// api
import {
  createFaq,
  updateFaq,
  deleteFaq,
  getFaqCategories,
  getFaqReferences,
} from './bff-service';
// router
import { ENavy, router } from '@/router';


const isDevMode = new URL(window.location.toString()).searchParams.has('_dev'); // Отладочная информация

// store
const store = useFaqEditItemStore();
const pageId = ref<number>(NaN);

const selectedType = ref<string>('_all'); 
const selectedCategory = ref<string[]>([]);
const selectedRefence = ref<string[]>([]); 
const route = useRoute();
const isCreating = ref(route.query.isCreating === 'true' || false);
const answerLength = computed(() => store.answer.length);

// категории и референсы
const categoryList = [] as {
  value: string;
  label: string;
}[];

const referencesList = [] as {
  value: string;
  label: string;
  path: string;
  type: string;
}[];

const referencesTypesList = [{
  value: '_all',
  label: 'Все'
}] as {
  value: string;
  label: string;
}[];


const fetchData = async () => {
  try {
    // получаем список категорий и референсов 
    const {
      0: resCategories, 
      1: resReferences
    } = await Promise.all([
      getFaqCategories(),
      getFaqReferences()
    ]);

    // заполняем категории
    resCategories.list.map(i=>{
      categoryList.push({
        value: i.id + '',
        label: i.name,
      })
    })

    // заполняем референсы
    resReferences.list.map(i=>{
      referencesList.push({
        value: i.id + '',
        label: i.reference_name,
        path: i.reference_path,
        type: i.reference_type
      })
    });

    // заполняем типы референсов
    var uniqReferenceTypes = new Set(resReferences.list.map(i=>i.reference_type + ''));
    [...uniqReferenceTypes.values()].forEach(i=>referencesTypesList.push({
      value: i,
      label: i
    }));

    // устанавливаем флаг загрузки данных страницы
    pageDataLoaded.value = true;
  } catch (error) {
    console.error(error);
    alert('Произошла ошибка, попробуйте перезагрузить страницу.');
  }
};

onMounted(() => {
  fetchData();
  if(!isCreating.value){
    const route = useRoute();
    const pageID = parseInt((route?.params?.id ?? '') + '', 10);
    if (Number.isInteger(pageID)) {
      pageId.value = pageID;
      store.getDataById(pageID);
    }
  }
});

onUnmounted(() => {
  if (!isCreating.value) {
    store.$reset();
    selectedType.value = '';
  }
});


// ожидание загрузки страницы и всех данных
const pageLoaded = ref<boolean>(false);
const pageDataLoaded = ref<boolean>(false);

watch(
  [
    pageDataLoaded,
    ()=>store.$loading
  ],

  ({0: dataLoaded, 1: storeLoading})=>{

    if (dataLoaded && (!storeLoading || isCreating.value)) {

      // подготавливаем референсы
      var faq_references = store.faq_references;
      if (Array.isArray(faq_references)) {
        selectedRefence.value = Array.from(new Set([
          ...selectedRefence.value,
          ...faq_references.map(i => i.id + '')
        ]));
      }

      // подготавливаем категории
      var faq_categories = store.faq_categories;
      if (Array.isArray(faq_categories)) {
        selectedCategory.value = Array.from(new Set([ 
          ...selectedCategory.value,
          ...faq_categories.map(i => i.id + '')
        ]));
      }

      pageLoaded.value = true
    }
  }
);

// кастомный фильтр для референсов
const customReferencesFilter = (options: TSelectOption[], search: string) => {
  const regexp = new RegExp(search, 'i');

  return options.filter((item) => {
    // фильтр по типу
    var referenceType = selectedType.value;
    if (referenceType && referenceType !== '_all') {
      if ( 
        (item as typeof referencesList[number]).type !== referenceType
      ) {
        return false;
      }
    }

    // фильтр по значению
    return regexp.test(item.label) 
    || regexp.test(item.value) 
    || regexp.test((item as typeof referencesList[number]).path)
  })
}

// сохранение и добавление

const submitFormHandler = (e: Event)=>{
  e.preventDefault();
  e.stopPropagation();

  const requestData = {
    question_text: store.question_text,
    answer: store.answer,
    categories: [...selectedCategory.value].map(Number),
    references: [...selectedRefence.value].map(Number),
  };
  
  if (isCreating.value) {
    // создание
    createFaq(requestData)
    .then((response)=>{
      if (response.status) {
        router.push(ENavy.faqQuestions);
      }
      else {
        alert('Произошла ошибка.\nПопробуйте сохранить ещё раз.');
      }
    })
    .catch((e)=>{
      console.error(e);
      alert('Произошла ошибка.\nПопробуйте сохранить ещё раз.');
    })
  }
  else {
    // редактирование
    updateFaq(pageId.value, requestData)
    .then((response)=>{
      if (response.status) {
        router.push(ENavy.faqQuestions);
      }
      else {
        alert('Произошла ошибка.\nПопробуйте сохранить ещё раз.');
      }
    })
    .catch((e)=>{
      console.error(e);
      alert('Произошла ошибка.\nПопробуйте сохранить ещё раз.');
    })
  }
}

// удаление
const deleteButtonHandler = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();

  if (!pageId.value) { return }

  var isApproved = confirm('Удалить вопрос?');

  if (!isApproved) { return }

  deleteFaq(pageId.value)
  .then((response)=>{
      if (response.status) {
        router.push(ENavy.faqQuestions);
      }
      else {
        alert('Произошла ошибка.\nПопробуйте удалить ещё раз.');
      }
    })
    .catch((e)=>{
      console.error(e);
      alert('Произошла ошибка.\nПопробуйте удалить ещё раз.');
    })
}
</script>

<template>
  <PageHeader>Редактирование FAQ</PageHeader>

  <Card v-if="!isUserCanViewFaq()" style="position:relative">
    <CardTitle>Нет прав на просмотр данных</CardTitle>
  </Card>

  <Card v-else-if="isUserCanEditFaq() && (pageId || isCreating)" style="position:relative">
    <Loader v-if="!pageLoaded" />

    <template v-else-if="store.$error">
      <h3>Произошла ошибка получения данных.</h3>
      <p><strong>Попробуйте перезагрузить страницу</strong>, если это не поможет <strong>обратитесь в
          поддержку</strong>.</p>
      <p><strong>Данные об ошибке.</strong></p>
      <p><strong>Обозначение ошибки: </strong>{{ store.$error.error }}</p>
      <p><strong>ID запроса: </strong>{{ store.$error.requestId }}</p>
      <p><strong>Код ответа: </strong>{{ store.$error.code ?? '' }}</p>
      <p>&nbsp;</p>
      <router-link :to="{ name: routeNames.faqQuestions, params: { status: cmiStatuses.all } }">Вернуться к списку
        вопросов.</router-link>
    </template>
    <template v-else-if="pageLoaded">
      <template v-if="isDevMode">
        <p>только для отладки:</p>
        <textarea :value="JSON.stringify(store, null, `\t`)" class="form-control form-control-sm"
          style="resize:vertical" />
        <p></p>
      </template>

      <CardTitle>Редактирование данных</CardTitle>
      <form @submit="submitFormHandler">
      <FormGroup>
        <Row class="tab-content tab-transparent-content">
          <div class="col-lg-6 textarea__faq-question">
            <div class="select-category faq-select">
              <label class="key-label" for="category-list">Категория</label>
              <SelectFaq
                v-if="pageDataLoaded"
                id="category-list"
                :options="categoryList" 
                :multiple="true"
                v-model="selectedCategory"
              >
              
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!selectedCategory.length"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </SelectFaq>
            </div>

            <label class="key-label margin-top-faq" for="faq-question">Вопрос</label>
            <textarea 
              v-model="store.question_text"
              id="faq-question"
              class="form-control form-control-sm"
              style="resize:vertical"
              required
            />

            <div class="margin-top-faq">
              <div class="reference-row">
                <div class="reference-type-select faq-select">
                  <label class="key-label" for="ref-type-filter">Тип референса</label>
                  <Select 
                    v-if="pageDataLoaded"
                    id="ref-type-filter"
                    :options="referencesTypesList" 
                    v-model="selectedType"
                  />
                </div>
                <div class="select-reference reference-select faq-select faq-reference-select">
                  <label class="key-label" for="refereces-list">Референсы</label>

                  <SelectFaq 
                    id="refereces-list"
                    :options="referencesList" 
                    v-model="selectedRefence" 
                    :multiple="true"
                    :label="'reference_name'"
                    :custom-filter="customReferencesFilter"
                  >
                    <template #option="{option}">
                      <template v-if="selectedType==='_all'">
                        <div :class="['faq-ref-option', `faq-ref-${option.type}`]">{{ option.label }}</div>
                      </template>

                      <template v-else-if="option.type === selectedType">
                        <div :class="['faq-ref-option', `faq-ref-${option.type}`]">{{ option.label }}</div>
                      </template>
                      
                      <template v-else>{{ '' }}</template>
                    </template>

                    <template #selectedOption="{ option, deselect }">
                      <span :class="['vs__selected faq-ref-selected-option', `faq-ref-${option.type}` ]">
                        {{ option.label }}
                        <button type="button" class="vs__deselect" @click="deselect(option)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                            <path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path>
                          </svg>
                        </button>
                      </span>
                    </template>
                    
                  </SelectFaq>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 textarea__faq-answer margin-top">
            <label class="key-label" for="faq-answer">Ответ</label>
            <textarea 
              v-model="store.answer" 
              id="faq-answer"
              class="form-control form-control-sm"
              style="resize:vertical"
              required
            />
            <span class="char-counter">Введено символов: {{ answerLength }}</span>
          </div>
        </Row>
        
        <button v-if="isCreating" class="btn btn-success btn-fw margin-top-faq">Сохранить</button> 
        <button v-else class="btn btn-success btn-fw margin-top-faq">Сохранить изменения</button> 
      </FormGroup>
      </form>

      <FormGroup v-if="!isCreating">
        <button 
          type="button" 
          class="btn btn-danger btn-icon-text faq-btn-danger"
          @click="deleteButtonHandler"
        >
          <i class="fa fa-trash-o"></i> Удалить вопрос
        </button>
      </FormGroup>
    </template>
  </Card>
</template>
  

<style lang="scss">
.textarea__faq-answer {
  position: relative;

  .char-counter {
    position: absolute;
    bottom: 10px;
    right: 25px;
    font-size: 16px;
    color: #6c757d;
  }
}

.reference-row {
  display: flex;
  gap: 10px;
  align-items: start;
}

.reference-type-select .vs__clear{
  display: none;
}

.reference-type-select{
  flex: 1;
  min-width: 130px;
}

.reference-select {
  flex: 4;
}

.select-category .vs__selected{
  padding: 6px 10px;
  font-size: 18px;
  border: solid 1px #000;
}

.select-reference .vs__selected{
  padding: 6px 10px;
  font-size: 18px;
  border: solid 1px #a461d8;
}

.margin-top-faq{
  margin-top: 14px;
}
.textarea__faq-question .form-control-sm{
  font-size: 20px;
  min-height: 80px;
}
.textarea__faq-answer .form-control-sm{
  font-size: 20px;
  height: 227px;
}

.channel-form input::placeholder{
    opacity: 0.6;
}

.title-price {
    margin-bottom: 1rem;
}

.--visible-row {
    margin-bottom: 1rem;
    display: initial !important;
}

.--hidden-row {
    display: none;
}

/* faq select */
.faq-select .vs__selected {
    text-align: left;
    text-align: left;
    font-size: 14px;
    line-height: 1.2em;
}

.vs__dropdown-option--selected {
  display: none;
}


.faq-reference-select {
  .vs__dropdown-option {
    padding: 0;
  }

  .faq-ref-option {
    padding: 10px;
  }

  .faq-ref-option::before, .faq-ref-selected-option::before {
    display: inline-block;
    font: normal normal normal 24px / 1 "Material Design Icons";
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-right: 5px;
  }

  .faq-ref-VIDEO::before {
    content: "\F567";
  }
  .faq-ref-LINK::before {
    content: "\F339";
  }
  .faq-ref-SERVICE::before {
    content: "\F1B1";
  }

  input[type="search"] {
    min-width: 50px;
  }
}

.textarea__faq-answer .form-control-sm,
.textarea__faq-question .form-control-sm {
    font-size: 15px;
    line-height: 1.1em;
    letter-spacing: 0.1px;
}

.faq-reference-select .vs__dropdown-menu{
  max-height: 220px;
  overflow-x: hidden;
}

.faq-reference-select .vs__dropdown-option{
  white-space: normal;
}

.faq-btn-danger{
  margin-bottom: 40px;
}
</style>
