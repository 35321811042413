import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row" }
const _hoisted_2 = { class: "text-center navbar-brand-wrapper d-flex align-items-center justify-content-center" }
const _hoisted_3 = { class: "navbar-menu-wrapper d-flex align-items-stretch" }
const _hoisted_4 = { class: "navbar-nav navbar-nav-right" }
const _hoisted_5 = { class: "nav-item nav-profile dropdown" }
const _hoisted_6 = { class: "nav-profile-img" }
const _hoisted_7 = ["alt", "src"]
const _hoisted_8 = { class: "nav-profile-text" }
const _hoisted_9 = { class: "mb-1 text-black" }
const _hoisted_10 = { class: "p-3 text-center bg-primary" }
const _hoisted_11 = ["alt", "src"]
const _hoisted_12 = { class: "p-2" }
const _hoisted_13 = { class: "dropdown-item py-1 d-flex align-items-center justify-content-between" }
const _hoisted_14 = { class: "dropdown-item py-1 d-flex align-items-center justify-content-between" }

import { defineComponent, ref } from 'vue';
// store
import {
    useUserStore,
    useNavyStore
} from '../store';
import defaultUserImage from '@/assets/img/user.svg';
import Informer from './Informer.vue';


// define component

export default /*@__PURE__*/_defineComponent({
  __name: 'Header',
  setup(__props) {


defineComponent({ name: 'AppHeader' });
const userNavOpen = ref<boolean>(false);


// store
const user = useUserStore();
const navy = useNavyStore();

// methods
const toggleUserNav = () => {
    userNavOpen.value = !userNavOpen.value;
};

const toggleLeftNavy = () => {
    navy.toggleLeftNavy();
};

const toggleMobileNavy = () => {
    navy.toggleMobileNavy();
};

// window.addEventListener('click', (e: Event) => {
//   console.log(userNavOpen.value)
//   // console.log(document.querySelector('.dropdown-menu.navbar-dropdown.dropdown-menu-end.p-0.border-0.font-size-sm'))
//   // console.log((e.target as any)?.closest('.dropdown-menu.navbar-dropdown.dropdown-menu-end.p-0.border-0.font-size-sm'))
//   if (userNavOpen.value) {
//     if ((e.target as any)?.closest('.dropdown-menu.navbar-dropdown.dropdown-menu-end.p-0.border-0.font-size-sm') === null) {
//       userNavOpen.value = false
//     }
//   }
// }) // @dev:

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Informer),
      _cache[1] || (_cache[1] = _createElementVNode("a", {
        class: "navbar-brand brand-logo",
        href: "/"
      }, [
        _createElementVNode("img", {
          alt: "logo",
          src: "https://tupa24.de/media/2022/12/logo.png"
        })
      ], -1)),
      _cache[2] || (_cache[2] = _createElementVNode("a", {
        class: "navbar-brand brand-logo-mini",
        href: "/"
      }, [
        _createElementVNode("img", {
          alt: "logo",
          src: "https://tupa24.de/media/2022/12/logo.png"
        })
      ], -1))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        class: "navbar-toggler navbar-toggler align-self-center",
        type: "button",
        "data-toggle": "minimize",
        onClick: toggleLeftNavy
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("span", { class: "mdi mdi-menu" }, null, -1)
      ])),
      _createElementVNode("ul", _hoisted_4, [
        _createElementVNode("li", _hoisted_5, [
          _createElementVNode("a", {
            "data-bs-toggle": "dropdown",
            "aria-expanded": "false",
            class: _normalizeClass(['nav-link dropdown-toggle', userNavOpen.value ? 'show' : ''].join(' ')),
            onClick: toggleUserNav
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("img", {
                alt: _unref(user).name,
                src: _unref(user).avatar || _unref(defaultUserImage)
              }, null, 8, _hoisted_7)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("p", _hoisted_9, _toDisplayString(_unref(user).name), 1)
            ])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["dropdown-menu navbar-dropdown dropdown-menu-end p-0 border-0 font-size-sm", userNavOpen.value && 'show']),
            "aria-labelledby": "profileDropdown",
            "data-x-placement": "bottom-end"
          }, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("img", {
                class: "img-avatar img-avatar48 img-avatar-thumb",
                alt: _unref(user).name,
                src: _unref(user).avatar || _unref(defaultUserImage)
              }, null, 8, _hoisted_11)
            ]),
            _createElementVNode("div", _hoisted_12, [
              _cache[5] || (_cache[5] = _createElementVNode("h5", { class: "dropdown-header text-uppercase ps-2 text-dark" }, " User Options ", -1)),
              _createElementVNode("a", _hoisted_13, [
                _createElementVNode("span", null, _toDisplayString(_unref(user).name), 1)
              ]),
              _createElementVNode("a", _hoisted_14, [
                _createElementVNode("span", null, _toDisplayString(_unref(user).email), 1)
              ]),
              _cache[6] || (_cache[6] = _createElementVNode("div", {
                role: "separator",
                class: "dropdown-divider"
              }, null, -1)),
              _cache[7] || (_cache[7] = _createElementVNode("h5", { class: "dropdown-header text-uppercase ps-2 text-dark mt-2" }, " Actions ", -1)),
              _createElementVNode("a", {
                onClick: _cache[0] || (_cache[0] = ()=>_unref(user).logout()),
                class: "dropdown-item py-1 d-flex align-items-center justify-content-between"
              }, _cache[4] || (_cache[4] = [
                _createElementVNode("span", null, "Log Out", -1),
                _createElementVNode("i", { class: "mdi mdi-logout ms-1" }, null, -1)
              ]))
            ])
          ], 2)
        ])
      ]),
      _createElementVNode("button", {
        class: "navbar-toggler navbar-toggler-right d-lg-none align-self-center",
        type: "button",
        "data-toggle": "offcanvas",
        onClick: toggleMobileNavy
      }, _cache[8] || (_cache[8] = [
        _createElementVNode("span", { class: "mdi mdi-menu" }, null, -1)
      ]))
    ])
  ]))
}
}

})