<script setup lang="ts">
import NewYearTree from './NewYearTree.vue';

const dateNow = new Date(); 
const date = dateNow.getDate();
const month = dateNow.getMonth();

const isNewYear = (month === 0 && date < 12) || (month === 11 && date > 11);
</script>

<template>
    <NewYearTree v-if="isNewYear" />
</template>