import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ENavy, router } from '@/router';
import { ITableCellProps } from '@/components/Table/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'FaqQuestionEdit',
  props: {
    dataKey: {},
    rowData: {},
    rowIndex: {},
    value: {},
    class: {}
  },
  setup(__props: any) {

const props = __props;

const clickHandler = (e: Event)=>{
    e.preventDefault();
    e.stopPropagation();
    
    if (props.rowData.id) {
        router.push(ENavy.faqQuestionEdit.replace(':id', props.rowData.id.toString()));
    }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("td", null, [
    _createElementVNode("button", {
      onClick: clickHandler,
      class: "btn btn-outline-secondary btn-icon",
      title: "Редактировать"
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("i", { class: "mdi mdi-pencil" }, null, -1)
    ]))
  ]))
}
}

})