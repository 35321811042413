import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "data-key": "is_super_moderator",
  "data-is-super": "true",
  class: "show-star"
}
const _hoisted_2 = { key: 1 }




export default /*@__PURE__*/_defineComponent({
  __name: 'ModeratorCell',
  props: {
    value: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_ctx.value)
    ? (_openBlock(), _createElementBlock("td", _hoisted_1, _cache[0] || (_cache[0] = [
        _createElementVNode("span", {
          class: "fa fa-star",
          "aria-hidden": "true"
        }, null, -1)
      ])))
    : (_openBlock(), _createElementBlock("td", _hoisted_2, _cache[1] || (_cache[1] = [
        _createElementVNode("span", null, null, -1)
      ])))
}
}

})