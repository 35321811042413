<script setup lang="ts">
import { deleteChannel, deleteUserFromBlackList } from '../bff-service';
import { useAsyncBlackListAllListStore } from '../store';
import { ITableCellProps } from '@/components/Table/types';

const props = defineProps<ITableCellProps>();
const tableStore = useAsyncBlackListAllListStore();

const deleteUserFromBlackListById = () => {

var isApproved = window.confirm(`Удалить пользователя ${props.rowData.user_id} (${props.rowData.username}), из чёрного  списка?`);
if (!isApproved) { return }

deleteUserFromBlackList(props.rowData.id)
  .then(() =>{
    tableStore.getData()
  })
  .catch((e)=>{
    alert(`Ошибка удаления пользователя.\n${e.error}\nRequest ID: ${e.requestId}`)
  })
}

</script>

<template>
  <td>
    <div class="d-flex gap-2 align-items-center">
      <button 
        class="btn btn-outline-danger btn-icon" 
        title="Удалить" 
        @click="deleteUserFromBlackListById"
      >
        <i class="mdi mdi-account-remove"></i>
      </button>
    </div>
  </td>
</template>
