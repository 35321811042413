<script setup lang="ts">
import { deleteChannel } from '../bff-service';
import { useAsyncChannelAllListStore } from '../store';
import { ITableCellProps } from '@/components/Table/types';

const props = defineProps<ITableCellProps>();
const tableStore = useAsyncChannelAllListStore();

const deleteChannelById = () => {

var isApproved = window.confirm(`Удалить каннал ${props.rowData.channel_id} (${props.rowData.name}) ?`);
if (!isApproved) { return }

deleteChannel(props.rowData.id)
  .then(() =>{
    tableStore.getData()
  })
  .catch((e)=>{
    alert(`Ошибка удаления канала.\n${e.error}\nRequest ID: ${e.requestId}`)
  })
}

</script>

<template>
  <td>
    <div class="d-flex gap-2 align-items-center">
      <button 
        class="btn btn-outline-danger btn-icon" 
        title="Удалить" 
        @click="deleteChannelById"
      >
        <i class="fa fa-trash-o"></i>
      </button>
    </div>
  </td>
</template>
