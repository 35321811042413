import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { deleteChannel, deleteUserFromBlackList } from '../bff-service';
import { useAsyncBlackListAllListStore } from '../store';
import { ITableCellProps } from '@/components/Table/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'BlackListDeleteUser',
  props: {
    dataKey: {},
    rowData: {},
    rowIndex: {},
    value: {},
    class: {}
  },
  setup(__props: any) {

const props = __props;
const tableStore = useAsyncBlackListAllListStore();

const deleteUserFromBlackListById = () => {

var isApproved = window.confirm(`Удалить пользователя ${props.rowData.user_id} (${props.rowData.username}), из чёрного  списка?`);
if (!isApproved) { return }

deleteUserFromBlackList(props.rowData.id)
  .then(() =>{
    tableStore.getData()
  })
  .catch((e)=>{
    alert(`Ошибка удаления пользователя.\n${e.error}\nRequest ID: ${e.requestId}`)
  })
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("td", null, [
    _createElementVNode("div", { class: "d-flex gap-2 align-items-center" }, [
      _createElementVNode("button", {
        class: "btn btn-outline-danger btn-icon",
        title: "Удалить",
        onClick: deleteUserFromBlackListById
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("i", { class: "mdi mdi-account-remove" }, null, -1)
      ]))
    ])
  ]))
}
}

})