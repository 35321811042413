<script setup lang="ts">
import { defineProps, ref } from 'vue';
import { TCategoryData, useAsyncCategoryAllListStore } from '../store'; // Подключите правильный путь
import { deleteCategory, updateCategory } from '../bff-service';

const tableStore = useAsyncCategoryAllListStore();
const props = defineProps<{ rowData: TCategoryData }>();
const { rowData } = props;
const name = ref(props.rowData.name);
const description = ref(rowData.description);

const applyEditing = async (row: TCategoryData) => {
  try {
    await updateCategory(row.id, { name: row.name, description: row.description });
    await tableStore.getData();
    row.isEditing = false; 
  } catch (error) {
    console.error('Ошибка при обновлении категории', error);
  }
};

const cancelEditing = (row: TCategoryData) => {
  row.isEditing = false;
  row.name = name.value;
  row.description = description.value;
};

const deleteCategoryById = () => {

var isApproved = window.confirm(`Удалить категорию "${props.rowData.name}?`);
if (!isApproved) { return }

deleteCategory(props.rowData.id)
  .then(()=>{
    tableStore.getData()
  })
  .catch((e)=>{
    alert(`Ошибка удаления категории.\n${e.error}\nRequest ID: ${e.requestId}`)
  })
}

</script>

<template>
  <td>
    <button 
      v-if="!rowData.isEditing" 
      class="btn btn-outline-secondary btn-icon" 
      title="Редактировать" 
      @click="rowData.isEditing = !rowData.isEditing"
    >
      <i class="mdi mdi-pencil"></i>
    </button>

    <div v-else class="d-flex gap-2 align-items-center">
      <button 
        class="btn btn-outline-success btn-icon" 
        title="Применить редактирование" 
        @click="applyEditing(rowData)"
      >
        <i class="mdi mdi-check"></i>
      </button>
      <button 
        class="btn btn-outline-primary btn-icon" 
        title="Отменить редактирование" 
        @click="cancelEditing(rowData)"
      >
        <i class="mdi mdi-close"></i>
      </button>
      <button 
        class="btn btn-outline-danger btn-icon" 
        title="Удалить" 
        @click="deleteCategoryById"
      >
        <i class="fa fa-trash-o"></i>
      </button>
    </div>
  </td>
</template>
