<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';
import { isUserCanEditDictionary, isUserCanViewDictionary } from '@/store';
import PageHeader from '@/components/kit/PageHeader.vue';
import Card from '@/components/kit/Card.vue';
import CardTitle from '@/components/kit/CardTitle.vue';
import { useRoute } from 'vue-router';
import { TJsonUseStore } from 'litsystems-vue-json-editor-dev/src';
import JsonViewer from 'litsystems-vue-json-editor-dev/src/JsonViewer.vue';
import { useDictionaryEditItemStore } from './store/dictionaryItemStore';
import InputTagDictionaryRegulars from './components/InputTagDictionaryRegulars.vue';
import InputTagDictionaryBanWord from './components/InputTagDictionaryBanWord.vue';

// store
const store = useDictionaryEditItemStore();
const dealId = ref<number>(NaN);

var useDictionaryStore: TJsonUseStore['useStore'];

const getUseStoreCb = (useStore: TJsonUseStore['useStore']) => {
  useDictionaryStore = useStore;

    const jsonStore = useStore();

    // устанавливаем расширения
    jsonStore.addExtensionComponent('dictionary-ban-word', InputTagDictionaryBanWord);
    jsonStore.addExtensionComponent('dictionary-input', InputTagDictionaryRegulars);

    jsonStore.updateExtensions({
      key: {
        'words': ['dictionary-ban-word'],
        'regulars': ['dictionary-input'],
      },
    });

    // устанавливаем классы
    jsonStore.updateClassNames({
      viewer: {
        key: {
          'words': "--hidden-row",
          'regulars': "--hidden-row",
        }
      }
    });

    jsonStore.updateHiddens({
      parentKey: [''],

      keyException: [
        'words',
        'regulars',
      ]
    });

    // изначальная установка даннных
    jsonStore.setReference(store.dictionary);
}


onMounted(() => {
    const route = useRoute();
    const deal = parseInt((route?.params?.id ?? '') + '', 10);

    // получаем данные по id страницы
    if (Number.isInteger(deal)) {
        dealId.value = deal;
        store.getDataById(deal); // Дождитесь получения данных
    }
});

onUnmounted(() => {
    store.$reset();
    useDictionaryStore?.().$reset();
});

const saveDictionaryHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();


    try {
        const dictionaryStore = useDictionaryStore();
        const referenceData = JSON.stringify(dictionaryStore.reference, null, 2);
        store.updateData(JSON.parse(referenceData));

    } catch (error) {
        console.error('Ошибка при сохранении изменений:', error);
    }
};

</script>

<template>
    <PageHeader>Редактирование словаря с запрещенными словами</PageHeader>

    <Card v-if="!isUserCanViewDictionary()" style="position:relative">
        <CardTitle>Нет прав на просмотр данных</CardTitle>
    </Card>

    <Card v-if="isUserCanEditDictionary()" style="position:relative">
        <Loader v-if="store.$loading" />
        <template v-else-if="store.$error">
            <h3>Произошла ошибка получения данных.</h3>
            <p><strong>Попробуйте перезагрузить страницу</strong>, если это не поможет <strong>обратитесь в
                    поддержку</strong>.
            </p>
            <p><strong>Данные об ошибке.</strong></p>
            <p><strong>Обозначение ошибки: </strong>{{ store.$error.error }}</p>
            <p><strong>ID запроса: </strong>{{ store.$error.requestId }}</p>
            <p><strong>Код ответа: </strong>{{ store.$error.code ?? '' }}</p>
            <p>&nbsp;</p>
        </template>
        <template v-else>
            <CardTitle>Данные словаря</CardTitle>
            <div class="row">
                <p>Название словаря:</p>
                <p style="color:#000">{{ store.dictionary_name }}</p>
            </div>

            <!-- Рендеринг редактора -->
            <CardTitle>Редактирование словаря</CardTitle>
            <JsonViewer :getUseStoreCb="getUseStoreCb" />
            
            <button v-if="store.isUpdated" class="btn btn-inverse-primary btn-fw" @click="saveDictionaryHandler">Сохранить
                изменения</button>

        </template>
    </Card>
</template>
  

<style lang="scss">
.title-price {
    margin-bottom: 1rem;
}

.--visible-row {
    margin-bottom: 1rem;
    display: initial !important;
}

.--hidden-row {
    display: none;
}</style>