import { get, post, put, del } from "@/service/bffService";
import {
  TFaqGetListResDto, 
  TFaqItemResDto, 
  TFaqPostedGetItemResDto, 
  TFaqReferenceListDto,
  TFaqCategoryListDto,
  TFaqCreate,
  TFaqUpdate,
  TCategoriesGetListResDto,
  TCategoryPostedGetItemResDto,
} from "./types/dto";
export { TBffResponseError } from '@/service/bffService';

export type TFaqSearchParams = {
	filter_category?: string;
	filter_question?: string;
}

export type TCategoriesSearchParams = {
  name: string;
  description: string;
}

// Faq list
export const getFaqQuestionsList = async (page: number, perPage: number, search?: TFaqSearchParams): Promise<TFaqGetListResDto> => {
  const searchParams = Object.entries(search || {}).map(filter=>`${filter[0]}=${encodeURI(filter[1])}`).join('&');

  return get<TFaqGetListResDto>(`/api/faq-pannel-questions?perPage=${perPage}&page=${page}&${searchParams}`);
}

// Faq list
export const getFaqPopulateAll = async (): Promise<any> => {
  return get<any>(`/api/faq-populate-questions`);
}

// Faq item
export const getFaqItem = async (id: number): Promise<TFaqItemResDto> => {
  return get<TFaqItemResDto>('/api/faq-edit-questions/' + id);
}

export const updateFaq = async (id: number, data: TFaqUpdate): Promise<any> => {
  return put<TFaqUpdate, { status: boolean }>('/api/faq-edit-questions/' + id, data);
}

export const createFaq = async (data: TFaqCreate): Promise<{ status: boolean }> => {
  return post<TFaqCreate, { status: boolean }>('/api/faq-create-questions', data);
};

export const deleteFaq = async (id: number): Promise<{ status: boolean }> => {
  return del<{ status: boolean }>('/api/faq-edit-questions/' + id);
};

// Faq list
export const getFaqCategories = async (): Promise<TFaqCategoryListDto> => {
  return get<TFaqCategoryListDto>(`/api/faq-get-categories`);
}

// Faq list
export const getFaqReferences = async (): Promise<TFaqReferenceListDto> => {
  return get<TFaqReferenceListDto>(`/api/faq-get-references`);
}

// Faq category
export const getFaqCategory = async (page: number,perPage: number,search?: TCategoriesSearchParams): Promise<TCategoriesGetListResDto> => {
  const searchParams = Object.entries(search || {}).map(filter=>`${filter[0]}=${encodeURI(filter[1])}`).join('&');

  return get<TCategoriesGetListResDto>(`/api/faq-get-category?perPage=${perPage}&page=${page}&${searchParams}`);
};

export const createCategory = async (data: TCategoryPostedGetItemResDto): Promise<TCategoryPostedGetItemResDto> => {
  return post('/api/faq-get-category', data);
};

export const updateCategory = async (id: number, data: object): Promise<any> => {
  return put<any, object>('/api/faq-get-category/' + id, data);
}

export const deleteCategory = async (id: number): Promise<any> => {
  return del<any>(`/api/faq-get-category/` + id);
};