import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "d-flex gap-2 align-items-center"
}

import { ref } from 'vue';
import { TCategoryData, useAsyncCategoryAllListStore } from '../store'; // Подключите правильный путь
import { deleteCategory, updateCategory } from '../bff-service';


export default /*@__PURE__*/_defineComponent({
  __name: 'CategoryEditTable',
  props: {
    rowData: {}
  },
  setup(__props: any) {

const tableStore = useAsyncCategoryAllListStore();
const props = __props;
const { rowData } = props;
const name = ref(props.rowData.name);
const description = ref(rowData.description);

const applyEditing = async (row: TCategoryData) => {
  try {
    await updateCategory(row.id, { name: row.name, description: row.description });
    await tableStore.getData();
    row.isEditing = false; 
  } catch (error) {
    console.error('Ошибка при обновлении категории', error);
  }
};

const cancelEditing = (row: TCategoryData) => {
  row.isEditing = false;
  row.name = name.value;
  row.description = description.value;
};

const deleteCategoryById = () => {

var isApproved = window.confirm(`Удалить категорию "${props.rowData.name}?`);
if (!isApproved) { return }

deleteCategory(props.rowData.id)
  .then(()=>{
    tableStore.getData()
  })
  .catch((e)=>{
    alert(`Ошибка удаления категории.\n${e.error}\nRequest ID: ${e.requestId}`)
  })
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("td", null, [
    (!_unref(rowData).isEditing)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "btn btn-outline-secondary btn-icon",
          title: "Редактировать",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(rowData).isEditing = !_unref(rowData).isEditing))
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("i", { class: "mdi mdi-pencil" }, null, -1)
        ])))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("button", {
            class: "btn btn-outline-success btn-icon",
            title: "Применить редактирование",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (applyEditing(_unref(rowData))))
          }, _cache[4] || (_cache[4] = [
            _createElementVNode("i", { class: "mdi mdi-check" }, null, -1)
          ])),
          _createElementVNode("button", {
            class: "btn btn-outline-primary btn-icon",
            title: "Отменить редактирование",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (cancelEditing(_unref(rowData))))
          }, _cache[5] || (_cache[5] = [
            _createElementVNode("i", { class: "mdi mdi-close" }, null, -1)
          ])),
          _createElementVNode("button", {
            class: "btn btn-outline-danger btn-icon",
            title: "Удалить",
            onClick: deleteCategoryById
          }, _cache[6] || (_cache[6] = [
            _createElementVNode("i", { class: "fa fa-trash-o" }, null, -1)
          ]))
        ]))
  ]))
}
}

})