import { defineStore } from 'pinia';
import { TBffResponseError, getFaqItem } from '../bff-service';


type TFaqEditItemStore = {
	// system
	$loading: boolean;
	$error?: TBffResponseError;
	// data
	id: number;
	question_text: string;
	answer: string;
	faq_categories: Array<Record<string, any>>;
	faq_question_variants: Array<Record<string, any>>;
	faq_keywords: Array<Record<string, any>>;
	faq_references: Array<Record<string, any>>;
	isUpdated: boolean;
}

// store
export const useFaqEditItemStore = defineStore({
	id: 'faq-item',

	state: (): TFaqEditItemStore => ({
		$loading: true,
		$error: void 0,
		// data
		id: NaN,
		question_text: '',
		answer: '',
		faq_categories: [],
		faq_question_variants: [],
		faq_keywords: [],
		faq_references: [],

		// @dev: 
		isUpdated: false,
	}),

	actions: {
		getDataById(dealId: number) {
			this.id = NaN;

			getFaqItem(dealId)
				.then((responseData) => {
					this.$error = void 0;
					// Пример установки значений store
					this.id = responseData.id;
					this.question_text = responseData.question_text;
					this.answer = responseData.answer;
					this.faq_categories = responseData.faq_categories?.data || [];
					this.faq_question_variants = responseData.faq_question_variants?.data || [];
					this.faq_keywords = responseData.faq_keywords?.data || [];
					this.faq_references = responseData.faq_references?.data || [];

					this.isUpdated = false;
				})
				.catch((e: TBffResponseError) => {
					this.$error = e;
				})
				.finally(() => {
					this.$loading = false;
				});

		},

	},
});