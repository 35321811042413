import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "cmi-deals-all-filters" }
const _hoisted_2 = {
  key: 0,
  class: "cmi-deals-all-filters"
}

import { ref, watch } from 'vue';
// components
import Input from '@/components/kit/Input.vue';
import { useAsyncCategoryAllListStore } from '../store';



export default /*@__PURE__*/_defineComponent({
  __name: 'AllCategoriesFilters',
  setup(__props) {

const tableStore = useAsyncCategoryAllListStore();
// tableStore;

const name = ref<string>('');
const description = ref<string>('');

watch([name, description], ()=>{
    // tableStore.searchBy
    tableStore.filterBy(1, [name.value]);
    tableStore.filterBy(2, [description.value]);
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("tbody", _hoisted_1, [
      _createElementVNode("tr", null, [
        _cache[2] || (_cache[2] = _createElementVNode("td", { colspan: "1" }, " Фильтры: ", -1)),
        _createElementVNode("td", null, [
          _createVNode(Input, {
            modelValue: name.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((name).value = $event)),
            type: "search"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("td", null, [
          _createVNode(Input, {
            modelValue: description.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((description).value = $event)),
            type: "search"
          }, null, 8, ["modelValue"])
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("td", null, null, -1)),
        _cache[4] || (_cache[4] = _createElementVNode("td", null, null, -1))
      ])
    ]),
    (_unref(tableStore).loading)
      ? (_openBlock(), _createElementBlock("tbody", _hoisted_2, _cache[5] || (_cache[5] = [
          _createElementVNode("tr", null, [
            _createElementVNode("td", { colspan: "8" }, " Загрузка: ")
          ], -1)
        ])))
      : _createCommentVNode("", true)
  ], 64))
}
}

})