import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createVNode as _createVNode, createCommentVNode as _createCommentVNode, unref as _unref } from "vue"

const _hoisted_1 = { class: "cmi-deals-all-filters filter-faq" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "faq__table-tag-category" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 0,
  class: "cmi-deals-all-filters"
}

import { onMounted, onUnmounted, ref, watch } from 'vue';
// components
import Input from '@/components/kit/Input.vue';
// store
import { useAsyncFaqListStore } from '../store';
import { getFaqCategories } from '../bff-service';



export default /*@__PURE__*/_defineComponent({
  __name: 'AllFaqQuestionsFilters',
  setup(__props) {

const tableStore = useAsyncFaqListStore();
const componentDataLoaded = ref<boolean>(false);
const selectedCategoryName = ref<string>('_all');
const question_text = ref<string>('');

const categoryList = [
  {
    value: '_all',
    label: 'Все'
  }
] as {
  value: string;
  label: string;
}[];

const fetchData = async () => {
  try {
    const resCategories = await getFaqCategories();
    resCategories.list.forEach(i => categoryList.push({
      value: i.id + '',
      label: i.name,
    }));

    componentDataLoaded.value = true;
  } catch (error) {
    console.error(error);
    alert('Произошла ошибка, попробуйте перезагрузить страницу.');
  }
};

onMounted(() => {
  fetchData();
});

onUnmounted(() => {
  tableStore.$reset();
});

watch([selectedCategoryName, question_text], ({
  0: category,
  1: question
}) => {
  // фильтр по категории
  var filterByCategory = [];
  if (!category || category !== '_all') {
    filterByCategory.push(parseInt(category, 10));
  }
  tableStore.filterBy(1, filterByCategory);

  // фильтр по вопросу
  var filterByQuestion = [];
  if (question) {
    filterByQuestion.push(question);
  }
  tableStore.filterBy(2, filterByQuestion);
});




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("tbody", _hoisted_1, [
      (componentDataLoaded.value)
        ? (_openBlock(), _createElementBlock("tr", _hoisted_2, [
            _cache[2] || (_cache[2] = _createElementVNode("td", {
              colspan: "1",
              class: "faq__table-tag-filter"
            }, " Фильтры: ", -1)),
            _createElementVNode("td", _hoisted_3, [
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedCategoryName).value = $event)),
                class: "form-control"
              }, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(categoryList, (option, k) => {
                  return _createElementVNode("option", {
                    key: k,
                    value: option.value
                  }, _toDisplayString(option.label), 9, _hoisted_4)
                }), 64))
              ], 512), [
                [_vModelSelect, selectedCategoryName.value]
              ])
            ]),
            _createElementVNode("td", null, [
              _createVNode(Input, {
                modelValue: question_text.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((question_text).value = $event)),
                type: "search"
              }, null, 8, ["modelValue"])
            ]),
            _cache[3] || (_cache[3] = _createElementVNode("td", null, null, -1))
          ]))
        : (_openBlock(), _createElementBlock("tr", _hoisted_5, _cache[4] || (_cache[4] = [
            _createElementVNode("td", {
              colspan: "4",
              class: "faq__table-tag-filter"
            }, " Загрузка фильтров... ", -1)
          ])))
    ]),
    (_unref(tableStore).loading)
      ? (_openBlock(), _createElementBlock("tbody", _hoisted_6, _cache[5] || (_cache[5] = [
          _createElementVNode("tr", null, [
            _createElementVNode("td", { colspan: "8" }, " Загрузка: ")
          ], -1)
        ])))
      : _createCommentVNode("", true)
  ], 64))
}
}

})