import { generateAsyncDataTableStore, definePromiseQueue, mapTableFiltersToSearchParams } from '@/components/Table';
import { getFaqQuestionsList, TFaqSearchParams } from '../bff-service';
import { TFaqGetListResDto } from '../types/dto';

export type TFaqData = {
  id: number;
  category: {id: number; name: string}[];
  question_text: string;
};

const allListPromiseQueue = definePromiseQueue<TFaqGetListResDto>();

export const useAsyncFaqListStore = generateAsyncDataTableStore<TFaqData>({
  initialState: {
    loading: true,
},

dataGetter: async (useStore) => {
  const store = useStore();

  await allListPromiseQueue.wait(allListGetter, useStore)
    .then(response=>{
      store.data = response.list.map( question => {
        return {
          id: question.id,
          category: question.categories,
          question_text: question.question_text,
        };
      });
      store.paginateTotal = response.paginate.filtered;
      store.paginateFiltered = response.paginate.filtered;
    })
    .catch(e=>{
      store.data = [];
      store.paginateTotal = 0;
    })
    .finally(()=>{
      store.loading = false;
    })
}
});

// получение списка заявок
const allListGetter = (): Promise<TFaqGetListResDto> => {
  const store = useAsyncFaqListStore();

  const search: TFaqSearchParams = {};

  if (store.filters?.[1]?.value[0]) {
    search.filter_category = store.filters[1].value[0]
  }
  if (store.filters?.[2]?.value[0]) {
    search.filter_question = store.filters[2].value[0]
  }
  const paginateQuestion = store.paginatePerPage = 50;
  return getFaqQuestionsList(store.paginatePage, paginateQuestion, search);
}
